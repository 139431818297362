import React, { useEffect, useRef, useState } from 'react'
import FormItem from 'antd/lib/form/FormItem'
import Editor from './LexicalEditor'

const V2Editor = ({
  getFieldDecorator,
  formItemLayout,
  backgroundColor,
  field,
  label,
}) => {
  return (<>
    {
      // change to use a loop
      ['en-US', 'zh-CN', 'zh-HK'].map((locale) => {
        return (
          <FormItem label={`${label}: ${locale}`} {...formItemLayout}>
            {getFieldDecorator(`${field}_${locale}`, {
              initialValue: '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}',
            })(<Editor backgroundColor={backgroundColor} />)}
            </FormItem>
        )
      })
    }
  </>)
}

export default V2Editor