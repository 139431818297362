import React from 'react'
import { Table } from 'antd'
import { TableRowEditButton } from '../../components/AppButton'
import FormattedDate from '../../components/FormattedDate'
import FormattedBool from '../../components/FormattedBool'

class ItemListTable extends React.Component {
  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 10,
        sorter: true,
      }, {
        title: 'Order',
        dataIndex: 'order',
        key: 'order',
        sorter: true,
      }, {
        title: 'Counsel',
        dataIndex: 'is_counsel',
        key: 'is_counsel',
        sorter: true,
        width: 10,
        render: bool => <FormattedBool bool={bool} />,
      }, {
        title: 'First Name',
        dataIndex: 'first_name_en-US',
        key: 'first_name_en-US',
        sorter: true,
      }, {
        title: 'Last Name',
        dataIndex: 'last_name_en-US',
        key: 'last_name_en-US',
        sorter: true,
      }, {
        title: 'Title',
        dataIndex: 'title_en-US',
        key: 'title_en-US',
        sorter: true,
      }, {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        render: text => <FormattedDate text={text} />,
      }, {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 300,
        render: (text, record) => (
          <span>
            <TableRowEditButton name='Basic Info' to={`${this.props.editPath}/${record.id}`} /><br />
            <TableRowEditButton name='Appointments and Public Offices' to={`/admin/members/${record.id}/cv_appointments_and_public_offices`} /><br />
            <TableRowEditButton name='Educations' to={`/admin/members/${record.id}/cv_educations`} /><br />
            <TableRowEditButton name='Publications and Artices' to={`/admin/members/${record.id}/cv_publications_and_articles`} /><br />
            <TableRowEditButton name='Practice Areas' to={`/admin/members/${record.id}/cv_practice_areas`} /><br />
            <TableRowEditButton name='Selected Cases' to={`/admin/members/${record.id}/cv_selected_cases`} /><br />
            <TableRowEditButton name='Scholarship and Prizes' to={`/admin/members/${record.id}/cv_scholarship_and_prizes`} />
          </span>
        ),
      },
    ]
    return (
      <Table
        rowKey={record => `item-row-${record.id}`}
        columns={columns}
        size='small'
        scroll={{ x: 800 }}
        {...this.props}
      />
    )
  }
}

export default ItemListTable
