import React from 'react'
import { Form, Input } from 'antd'
import { Collapse, Icon } from 'antd';
import Markdown from '@voyagertech/markdown-to-jsx';

import './index.less';

const FormItem = Form.Item
const { TextArea } = Input
const { Panel } = Collapse

const MarkDownPreview = ({ input }) => {
  const parsedInput = input && input !== ' ' && input !== '\n' ? String(`${input}\n`) : ''
  return (
    <Collapse className='markdownpreview'>
      <Panel header='Preview' key='1'>
        {parsedInput.length > 0 &&
          <Markdown>{`${parsedInput}`}</Markdown>
        }
      </Panel>
    </Collapse>)
}

const MultiLanguageField = ({
  field,
  label,
  formItemLayout,
  getFieldDecorator,
  v2 = false,
  isRequired = true,
  textArea = false,
  data = {},
  enOnlyRequired = false,
  code = false,
}) => {
  let rules = []
  if (isRequired) {
    rules = [{ required: true, message: 'Please input a valid value.' }]
  }
  const chRules = enOnlyRequired ? [] : rules
  return (
    <React.Fragment>
      {code &&
        <>
          <FormItem label={`${label}: en-US`} {...formItemLayout}>
            {getFieldDecorator(`${field}_en-US`, {
              rules,
            })(<TextArea
              style={{ fontFamily: 'monospace' }}
              autosize={{ minRows: 5 }}
              autoComplete='off'
            />)}
          </FormItem>
          <FormItem label={`${label}: zh-CN`} {...formItemLayout}>
            {getFieldDecorator(`${field}_zh-CN`, {
              chRules,
            })(<TextArea
              style={{ fontFamily: 'monospace' }}
              autosize={{ minRows: 5 }}
              autoComplete='off'
            />)}
          </FormItem>
          <FormItem label={`${label}: zh-HK`} {...formItemLayout}>
            {getFieldDecorator(`${field}_zh-HK`, {
              chRules,
            })(<TextArea
              style={{ fontFamily: 'monospace' }}
              autosize={{ minRows: 5 }}
              autoComplete='off'
            />)}
          </FormItem>
        </>
        ||
        <>
          <FormItem label={`${label}: en-US`} {...formItemLayout}>
            {textArea && data &&
              data[`${field}_en-US`] &&
              <MarkDownPreview input={(data[`${field}_en-US`]).value} />}
            {getFieldDecorator(`${field}_en-US`, {
              rules,
            })(textArea ? <TextArea autosize={{ minRows: 6 }} /> : <Input />)}
          </FormItem>
          <FormItem label={`${label}: zh-CN`} {...formItemLayout}>
            {textArea && data &&
              data[`${field}_zh-CN`] &&
              <MarkDownPreview input={(data[`${field}_zh-CN`]).value} />}
            {getFieldDecorator(`${field}_zh-CN`, {
              chRules,
            })(textArea ? <TextArea autosize={{ minRows: 6 }} /> : <Input />)}
          </FormItem>
          <FormItem label={`${label}: zh-HK`} {...formItemLayout}>
            {textArea && data &&
              data[`${field}_zh-HK`] &&
              <MarkDownPreview input={(data[`${field}_zh-HK`]).value} />}
            {getFieldDecorator(`${field}_zh-HK`, {
              chRules,
            })(textArea ? <TextArea autosize={{ minRows: 6 }} /> : <Input />)}
          </FormItem>
        </>
      }
    </React.Fragment>
  )
}

export default MultiLanguageField
