import { combineReducers } from 'redux'
import app from './app'
import home from './home'
import login from './login'
import admin from './admin'
import pageList from './pageList'
import pageForm from './pageForm'
import practiceAreaList from './practiceAreaList'
import practiceAreaForm from './practiceAreaForm'
import administratorList from './administratorList'
import administratorForm from './administratorForm'
import pageTemplateList from './pageTemplateList'
import pageTemplateForm from './pageTemplateForm'
import memberList from './memberList'
import memberForm from './memberForm'
import cvAppointmentsAndPublicOfficeList from './cvAppointmentsAndPublicOfficeList'
import cvAppointmentsAndPublicOfficeForm from './cvAppointmentsAndPublicOfficeForm'
import cvEducationList from './cvEducationList'
import cvEducationForm from './cvEducationForm'
import cvPublicationsAndArticleList from './cvPublicationsAndArticleList'
import cvPublicationsAndArticleForm from './cvPublicationsAndArticleForm'
import cvPracticeAreaList from './cvPracticeAreaList'
import cvPracticeAreaForm from './cvPracticeAreaForm'
import cvSelectedCaseList from './cvSelectedCaseList'
import cvSelectedCaseForm from './cvSelectedCaseForm'
import cvSelectedCasesCaseList from './cvSelectedCasesCaseList'
import cvSelectedCasesCaseForm from './cvSelectedCasesCaseForm'
import cvScholarshipAndPrizeList from './cvScholarshipAndPrizeList'
import cvScholarshipAndPrizeForm from './cvScholarshipAndPrizeForm'
import newList from './newList'
import newForm from './newForm'
import eventList from './eventList'
import eventForm from './eventForm'
import assetList from './assetList'
import assetForm from './assetForm'

export default combineReducers({
  app,
  home,
  login,
  admin,
  pageList,
  pageForm,
  practiceAreaList,
  practiceAreaForm,
  administratorList,
  administratorForm,
  pageTemplateList,
  pageTemplateForm,
  memberList,
  memberForm,
  cvAppointmentsAndPublicOfficeList,
  cvAppointmentsAndPublicOfficeForm,
  cvEducationList,
  cvEducationForm,
  cvPublicationsAndArticleList,
  cvPublicationsAndArticleForm,
  cvPracticeAreaList,
  cvPracticeAreaForm,
  cvSelectedCaseList,
  cvSelectedCaseForm,
  cvSelectedCasesCaseList,
  cvSelectedCasesCaseForm,
  cvScholarshipAndPrizeList,
  cvScholarshipAndPrizeForm,
  newList,
  newForm,
  eventList,
  eventForm,
  assetList,
  assetForm,
})
