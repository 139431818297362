import React from 'react'
import moment from 'moment'

class FormattedDateTime extends React.PureComponent {
  render() {
    const { text } = this.props
    return (
      <React.Fragment>
        {text && (new Date(text)).toLocaleString()}
      </React.Fragment>
    )
  }
}

// eslint-disable-next-line react/no-multi-comp
export class FormattedDate extends React.PureComponent {
  render() {
    const { text, yearOnly } = this.props
    return (
      <React.Fragment>
        {text && !!yearOnly && (moment(text).format('YYYY'))}
        {text && !yearOnly && (moment(text).format('YYYY-MM-DD'))}
      </React.Fragment>
    )
  }
}

export default FormattedDateTime
