import * as ActionTypes from '../constants/actionTypes'
import api from '../utils/api'

export const fetchItems = (params = {}) => async (dispatch) => {
  dispatch({
    type: ActionTypes.PAGESTEMPLATELIST_FETCHITEMS_LOAD,
    pageId: params.pageId,
  })
  try {
    const {
      pageId,
    } = params
    const result = await api.get(`pages/${pageId}`)
    const {
      ui: {
        title: {
          'en-US': uiTitle,
        },
      },
      templateArrangments: {
        data: items,
        total,
      },
    } = result
    dispatch({
      type: ActionTypes.PAGESTEMPLATELIST_FETCHITEMS_SUCCEED,
      items,
      uiTitle,
      total,
    })
  } catch (error) {
    dispatch({
      type: ActionTypes.PAGESTEMPLATELIST_FETCHITEMS_FAIL,
      fetchItemsError: error.message,
    })
  }
}