import React from 'react'
import { SketchPicker } from 'react-color'


const Colorpicker = ({ value, onChange }) => {
  const handleChange = (color) => {
    onChange(color.hex)
  }
  return (<SketchPicker
    onChange={handleChange}
    color={value}
    presetColors={[
      'TRANSPARENT',
      '#BC9150',
      '#000000',
      '#414141',
      '#FFFFFF',
    ]}
  />)
}

export default Colorpicker
