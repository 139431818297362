import React from 'react'
import { $insertNodes, createCommand } from 'lexical'
// eslint-disable-next-line import/extensions
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
// eslint-disable-next-line import/extensions
import { $createMentionNode } from '../MentionNode'

export const INSERT_MENTION_COMMAND = createCommand('INSERT_MENTION_COMMAND')

export default function MentionNodePlugin() {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    const removeListener = editor.registerCommand(
      INSERT_MENTION_COMMAND,
      (payload) => {
        editor.update(() => {
          $insertNodes([$createMentionNode(payload)])
        })

        return true
      },
      0,
    );

    return () => {
      removeListener()
    }
  }, [editor])

  return null
}
