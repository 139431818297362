import React from 'react'
import { Form, Input, Button, Modal, InputNumber, Tabs, DatePicker, Checkbox } from 'antd'
import { Collapse } from 'antd'
import moment from 'moment'
import Markdown from '@voyagertech/markdown-to-jsx'
import ImageUpload from '../../components/ImageUpload'
import { keys } from '../../actions/memberForm'
import MultiLanguageField from '../../components/MultiLanguageField'

const FormItem = Form.Item
const { TabPane } = Tabs
const { Panel } = Collapse
const { TextArea } = Input

const MarkDownPreview = ({ input }) => {
  const parsedInput = input ? String(`${input}  
  `) : ''
  return (
    <Collapse className='markdownpreview'>
      <Panel header='Preview' key='1'>
        {parsedInput.length > 0 &&
          <Markdown>{`${parsedInput}`}</Markdown>
        }
      </Panel>
    </Collapse>)
}
class ItemForm extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleOnDelete = this.handleOnDelete.bind(this)
  }

  handleOnSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return
      }
      this.props.onSubmit(values)
    })
  }

  handleOnDelete(e) {
    e.preventDefault()
    const handleOnOk = () => { this.props.onDelete() }
    Modal.confirm({
      title: 'Delete Item',
      content: 'Are you sure to delete this item?',
      okType: 'danger',
      onOk() {
        handleOnOk()
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
      },
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 12,
          offset: 7,
        },
      },
    }
    const isCreateForm = this.props.type === 'create'
    return (
      <Form onSubmit={this.handleOnSubmit}>
        <FormItem label='Is Counsel?' {...formItemLayout}>
          {getFieldDecorator('is_counsel', {
            valuePropName: 'checked',
          })(<Checkbox autoComplete='off' />)}
        </FormItem>
        <FormItem label='Is Arbitrator CV?' {...formItemLayout}>
          {getFieldDecorator('is_arbitrator_cv', {
            valuePropName: 'checked',
          })(<Checkbox autoComplete='off' />)}
        </FormItem>
        <FormItem label='Is Mediator CV?' {...formItemLayout}>
          {getFieldDecorator('is_mediator_cv', {
            valuePropName: 'checked',
          })(<Checkbox autoComplete='off' />)}
        </FormItem>
        <FormItem label='Order' {...formItemLayout}>
          {getFieldDecorator('order', {
            rules: [{ required: true, message: 'Please input a valid value.' }],
          })(<InputNumber autoComplete='off' />)}
        </FormItem>
        <FormItem label='Url snag' {...formItemLayout}>
          {getFieldDecorator('snag', {
            rules: [{ required: true, message: 'Please input a valid value.' }],
          })(<Input autoComplete='off' />)}
        </FormItem>
        <FormItem label='CV Language Support' {...formItemLayout}>
        English: {getFieldDecorator('support_lang_en-US', {
          valuePropName: 'checked',
        })(<Checkbox autoComplete='off' />)}<br />
        Chinese CN: {getFieldDecorator('support_lang_zh-CN', {
          valuePropName: 'checked',
        })(<Checkbox autoComplete='off' />)}<br />
        Chinese HK: {getFieldDecorator('support_lang_zh-HK', {
            valuePropName: 'checked',
          })(<Checkbox autoComplete='off' />)}
        </FormItem>
        <FormItem label='call date' {...formItemLayout}>
          {getFieldDecorator('startDate', {
            initialValue: moment('1990/09/05'),
          })(<DatePicker format='YYYY/MM/DD' />)}
        </FormItem>
        <FormItem label='Photo' {...formItemLayout}>
          {getFieldDecorator('photo_url', {
          })(<ImageUpload />)}
        </FormItem>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='en-US' key='1'>
            <FormItem label='First Name' {...formItemLayout}>
              {getFieldDecorator('first_name_en-US', {
                rules: [{ required: true, message: 'Please input a valid value.' }],
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='Last Name' {...formItemLayout}>
              {getFieldDecorator('last_name_en-US', {
                rules: [{ required: true, message: 'Please input a valid value.' }],
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='English Name is asian style' {...formItemLayout}>
              {getFieldDecorator('asian_name_display', {
                valuePropName: 'checked',
              })(<Checkbox autoComplete='off' />)}
            </FormItem>
            <FormItem label='Leading Statement' {...formItemLayout}>
              {getFieldDecorator('leading_statement_en-US', {
              })(<TextArea autosize={{ minRows: 6 }} autoComplete='off' />)}
            </FormItem>
            <FormItem label='Title' {...formItemLayout}>
              {getFieldDecorator('title_en-US', {
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='Secretary Name' {...formItemLayout}>
              {getFieldDecorator('secretary_en-US', {
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='Professional Qualication: Extra Data' {...formItemLayout}>
              { this.props.formFieldValues && this.props.formFieldValues['pq_extraData_en-US'] && <MarkDownPreview input={(this.props.formFieldValues[`pq_extraData_en-US`]).value} /> }
              {getFieldDecorator('pq_extraData_en-US', {
              })(<TextArea autosize={{ minRows: 6 }} />)}
            </FormItem>
          </TabPane>
          <TabPane tab='zh-HK' key='2'>
            <FormItem label='First Name' {...formItemLayout}>
              {getFieldDecorator('first_name_zh-HK', {
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='Last Name' {...formItemLayout}>
              {getFieldDecorator('last_name_zh-HK', {
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='Leading Statement' {...formItemLayout}>
              {getFieldDecorator('leading_statement_zh-HK', {
              })(<TextArea autosize={{ minRows: 6 }} autoComplete='off' />)}
            </FormItem>
            <FormItem label='Title' {...formItemLayout}>
              {getFieldDecorator('title_zh-HK', {
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='Secretary Name' {...formItemLayout}>
              {getFieldDecorator('secretary_zh-HK', {
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='Professional Qualication: Extra Data' {...formItemLayout}>
              { this.props.formFieldValues && this.props.formFieldValues['pq_extraData_zh-HK'] && <MarkDownPreview input={(this.props.formFieldValues[`pq_extraData_zh-HK`]).value} /> }
              {getFieldDecorator('pq_extraData_zh-HK', {
              })(<TextArea autosize={{ minRows: 6 }} />)}
            </FormItem>
          </TabPane>
          <TabPane tab='zh-CN' key='3'>
            <FormItem label='First Name' {...formItemLayout}>
              {getFieldDecorator('first_name_zh-CN', {
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='Last Name' {...formItemLayout}>
              {getFieldDecorator('last_name_zh-CN', {
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='Leading Statement' {...formItemLayout}>
              {getFieldDecorator('leading_statement_zh-CN', {
              })(<TextArea autosize={{ minRows: 6 }} autoComplete='off' />)}
            </FormItem>
            <FormItem label='Title' {...formItemLayout}>
              {getFieldDecorator('title_zh-CN', {
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='Secretary Name' {...formItemLayout}>
              {getFieldDecorator('secretary_zh-CN', {
              })(<Input autoComplete='off' />)}
            </FormItem>
            <FormItem label='Professional Qualication: Extra Data' {...formItemLayout}>
              { this.props.formFieldValues && this.props.formFieldValues['pq_extraData_zh-CN'] && <MarkDownPreview input={(this.props.formFieldValues[`pq_extraData_zh-CN`]).value} /> }
              {getFieldDecorator('pq_extraData_zh-CN', {
              })(<TextArea autosize={{ minRows: 6 }} />)}
            </FormItem>
          </TabPane>
        </Tabs>
        <hr />
        <FormItem label='Linkedin' {...formItemLayout}>
          {getFieldDecorator('linkedin', {
          })(<Input autoComplete='off' />)}
        </FormItem>
        <FormItem label='CV link - en-US' {...formItemLayout}>
          {getFieldDecorator('cv_en-US', {
          })(<ImageUpload />)}
        </FormItem>
        <FormItem label='CV link - zh-CN' {...formItemLayout}>
          {getFieldDecorator('cv_zh-CN', {
          })(<ImageUpload />)}
        </FormItem>
        <FormItem label='CV link - zh-HK' {...formItemLayout}>
          {getFieldDecorator('cv_zh-HK', {
          })(<ImageUpload />)}
        </FormItem>
        <FormItem label='Professional Qualication: is Mediator?' {...formItemLayout}>
          {getFieldDecorator('pq_mediator', {
            valuePropName: 'checked',
          })(<Checkbox autoComplete='off' />)}
        </FormItem>
        <FormItem label='Medicator CV avaliable?' {...formItemLayout}>
          {getFieldDecorator('cv_mediator', {
            valuePropName: 'checked',
          })(<Checkbox autoComplete='off' />)}
        </FormItem>
        <FormItem label='Professional Qualication: is Arbitrator?' {...formItemLayout}>
          {getFieldDecorator('pq_arbitrator', {
            valuePropName: 'checked',
          })(<Checkbox autoComplete='off' />)}
        </FormItem>
        <FormItem label='Arbitrator CV avaliable?' {...formItemLayout}>
          {getFieldDecorator('cv_arbitrator', {
            valuePropName: 'checked',
          })(<Checkbox autoComplete='off' />)}
        </FormItem>
        <FormItem label='Professional Qualication: is Silks?' {...formItemLayout}>
          {getFieldDecorator('pq_inner_bar', {
            valuePropName: 'checked',
          })(<Checkbox autoComplete='off' />)}
        </FormItem>
        <FormItem label='Tel' {...formItemLayout}>
          {getFieldDecorator('tel', {
          })(<Input autoComplete='off' />)}
        </FormItem>
        <FormItem label='Tel 2' {...formItemLayout}>
          {getFieldDecorator('tel1', {
          })(<Input autoComplete='off' />)}
        </FormItem>
        <FormItem label='Email' {...formItemLayout}>
          {getFieldDecorator('email', {
          })(<Input autoComplete='off' />)}
        </FormItem>
        <FormItem label='Email 2' {...formItemLayout}>
          {getFieldDecorator('email1', {
          })(<Input autoComplete='off' />)}
        </FormItem>
        <FormItem label='Fax' {...formItemLayout}>
          {getFieldDecorator('fax', {
          })(<Input autoComplete='off' />)}
        </FormItem>
        <FormItem label='Secretary Email' {...formItemLayout}>
          {getFieldDecorator('secretary_email', {
          })(<Input autoComplete='off' />)}
        </FormItem>
        <FormItem label='Secretary Tel' {...formItemLayout}>
          {getFieldDecorator('secretary_tel', {
          })(<Input autoComplete='off' />)}
        </FormItem>
        <hr />
        <MultiLanguageField
          label='Profile'
          field='profile'
          formItemLayout={formItemLayout}
          getFieldDecorator={getFieldDecorator}
          textArea
          isRequired={false}
          data={this.props.formFieldValues}
        />
        <FormItem label='Table html for preview info' {...formItemLayout}>
          <pre>{'<table><tr><th>Key</th><td>Data</td></tr></table>'}</pre>
        </FormItem>
        <MultiLanguageField
          label='Preview Info'
          field='preview_info'
          formItemLayout={formItemLayout}
          getFieldDecorator={getFieldDecorator}
          textArea
          enOnlyRequired
          data={this.props.formFieldValues}
        />
        <hr />
        <h2>Members Listing</h2>
        <MultiLanguageField
          label='Professional Qualication: Mediator'
          field='pq_mediator_qualification'
          formItemLayout={formItemLayout}
          getFieldDecorator={getFieldDecorator}
          textArea
          isRequired={false}
          data={this.props.formFieldValues}
        />
        <MultiLanguageField
          label='Professional Qualication: Arbitrator'
          field='pq_arbitrator_qualification'
          formItemLayout={formItemLayout}
          getFieldDecorator={getFieldDecorator}
          textArea
          isRequired={false}
          data={this.props.formFieldValues}
        />
        <MultiLanguageField
          label='Profile Summary ( for members list )'
          field='member_short_bio'
          formItemLayout={formItemLayout}
          getFieldDecorator={getFieldDecorator}
          textArea
          enOnlyRequired
          data={this.props.formFieldValues}
        />
        <hr />
        <h2>Sections in the CV</h2>
        <FormItem label='section html for extra listing itmes:' {...formItemLayout}>
          <pre>{'<div class="section"><h3>Header</h3><ul><li>listing</li></ul></div>'}</pre>
        </FormItem>
        <MultiLanguageField
          label='Extra Listing Items '
          field='extra_listings'
          formItemLayout={formItemLayout}
          getFieldDecorator={getFieldDecorator}
          textArea
          isRequired={false}
          data={this.props.formFieldValues}
        />
        <MultiLanguageField
          label='Languages'
          field='languages'
          formItemLayout={formItemLayout}
          getFieldDecorator={getFieldDecorator}
          textArea
          isRequired={false}
          data={this.props.formFieldValues}
        />
        <FormItem {...tailFormItemLayout}>
          <Button
            loading={this.props.isCreateItemLoading || this.props.isEditItemLoading}
            type='primary'
            htmlType='submit'
            style={{ marginRight: '12px' }}
          >
            {isCreateForm ? 'Create' : 'Save'}
          </Button>
          {
            !isCreateForm &&
            <Button
              loading={this.props.isDeleteItemLoading}
              type='danger'
              htmlType='button'
              onClick={this.handleOnDelete}
            >
              Delete
            </Button>
          }
        </FormItem>
      </Form>
    )
  }
}

const CustomizedForm = Form.create({
  onFieldsChange(props, changedFields) {
    props.onFieldsChange(changedFields)
  },
  mapPropsToFields(props) {
    const { formFieldValues = {} } = props
    const ks = keys.length === 0 ? Object.keys(formFieldValues) : keys
    const fields = ks.reduce((prev, key) => (
      { ...prev, [key]: Form.createFormField(formFieldValues[key]) }
    ), {})
    return fields
  },
  // onValuesChange(_, values) {
  //   console.log(values)
  // },
})(ItemForm)

export default CustomizedForm
