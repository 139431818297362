import React from 'react'
import { Card, Icon, Row, Col } from 'antd'

const { Meta } = Card;

export const Introduction = ({ templateName }) => {
  switch (templateName) {
    case 'hero-text': 
      return <HeroTextIntroduction />
    case 'hero-pic': 
      return <HeroPicIntroduction />
    case 'content-pic': 
      return <ContentPicIntroduction />
    case 'v2-carousell':
      return <V2CarouselIntroduction />
    case 'v2-freedom':
      return <V2FreeDomIntroduction />
    case 'v2-contact':
      return <V2ContactIntroduction />
    default:
      return <div>&nbsp;</div>
  }
}

const HeroTextIntroduction = () => (
  <Card
    style={{ width: 540 }}
    cover={<div style={{ fontSize: 25, border: '1px solid #f1f1f1', textAlign: 'center', padding: 10 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </div>}
  >
    <Meta
      title='Hero Text'
      description='A block for several paragraphs or introduction'
    />
  </Card>
)

const HeroPicIntroduction = () => (
  <Card
    style={{ width: 540 }}
    cover={<div style={{ fontSize: 25, padding: 150, border: '1px solid #f1f1f1', textAlign: 'center' }}><Icon type="picture" /></div>}
  >
    <Meta
      title='Hero Pic'
      description='A block for edge to edge image'
    />
  </Card>
)

const ContentPicIntroduction = () => (
  <Card
    style={{ width: 540 }}
    cover={<div style={{ fontSize: 20, border: '1px solid #f1f1f1', textAlign: 'center', padding: 30 }}><Row>
    <Col span={12} ><Icon type="picture" /></Col><Col span={12}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Col></Row></div>}
  >
    <Meta
      title='Hero Text'
      description='A block for several paragraphs on the right, and a picture on the left, when it is in phone screen the image will be hidden.'
    />
  </Card>
)

const V2CarouselIntroduction = () => (
  <Card
    style={{ width: 540 }}
    cover={<div style={{ fontSize: 20, border: '1px solid #f1f1f1', textAlign: 'center', padding: 30, position: 'relative' }}><Row>
    <Col span={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}><div
    style={{
      width: '100%',
      height: '220px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}><Icon type="picture" /></div></Col><Col span={12}><div style={{
      width: '80%',
      height: '100%',
      textAlign: 'left',
      display: 'flex',
      alignSelf: 'center',
    }}><br />TEMPLE CHAMBERS<br /><br />
IS ONE OF HONG KONG'S LEADING BARRISTERS' CHAMBERS</div></Col></Row></div>}
  >
    <Meta
      title='Carousel (v2 only)'
      description='A customizable, but styled carousel that store a picture on the side and content on the other side.  When it is in phone screen the image will be on the top.  This is only available in v2'
    />
  </Card>
)

const V2FreeDomIntroduction = () => (
  <Card
    style={{ width: 540 }}
    cover={<div style={{ fontSize: 20, border: '1px solid #f1f1f1', textAlign: 'center', padding: 30, position: 'relative' }}><code>Your code goes here...</code></div>}
  >
    <Meta
      title='Freedom (v2 only)'
      description='It is a div that you can put anything you want, you can specify your own css style and html.  If you dont understand the above this is prob. not for you.  This is only available in v2'
    />
  </Card>
)

const V2ContactIntroduction = () => (
  <Card
    style={{ width: 540 }}
    cover={<div style={{ fontSize: 20, border: '1px solid #f1f1f1', textAlign: 'center', padding: 30, position: 'relative' }}><Row>
    <Col span={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}><div
    style={{
      width: '100%',
      height: '220px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}><Icon type="picture" /></div></Col><Col span={12}><div style={{
      width: '80%',
      height: '100%',
      textAlign: 'left',
      display: 'flex',
      alignSelf: 'center',
    }}>Contact Info</div></Col></Row></div>}
  >
    <Meta
      title='Contact US (v2 only)'
      description='A customizable, but styled carousel that store a map (change based on language) on the side and contact info on the other side.  When it is in phone screen the map will be on the top.  This is only available in v2'
    />
  </Card>
)