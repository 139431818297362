// turn item with specific keys to a field object for antd form
export default (item = {}, keys = []) => {
  if (keys.length === 0) {
    return Object.keys(item).reduce((prev, k) => ({
      ...prev,
      [k]: { name: k, value: item[k] },
    }), {})
  }
  return keys.reduce((prev, key) => ({
    ...prev,
    ...{ [key]: { name: key, value: item[key] } },
  }), {})
}

export const fieldLang = ['en-US', 'zh-HK', 'zh-CN']

export const getFieldWithLangKey = ({ fieldTitle, objString, languages, parse = true }) => {
  let o = objString
  if (o === null) return ''
  if (parse) {
    o = JSON.parse(objString)
  }
  return languages.reduce((prev, l) => ({
    ...prev,
    [`${fieldTitle}_${l}`]: o[l],
  }), {})
}

export const getNewObjFromDirtyLangObj = ({ fieldTitle, obj, languages, parse = true, pure = false }) => {
  const nObj = obj
  const fieldWithLangKey = languages.reduce((prev, cur) => {
    const val = obj[`${fieldTitle}_${cur}`]
    delete nObj[`${fieldTitle}_${cur}`]
    return {
      ...prev,
      [cur]: val,
    }
  }, {})
  if (parse) {
    return { ...nObj, [fieldTitle]: JSON.stringify(fieldWithLangKey) }
  }
  if (pure) {
    return { [fieldTitle]: fieldWithLangKey }
  }
  return { ...nObj, [fieldTitle]: fieldWithLangKey }
}