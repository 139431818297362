import {
  PAGESTEMPLATELIST_FETCHITEMS_LOAD,
  PAGESTEMPLATELIST_FETCHITEMS_SUCCEED,
  PAGESTEMPLATELIST_FETCHITEMS_FAIL,
  PAGESTEMPLATELIST_CHANGETABLE_CHANGE,
  PAGESTEMPLATELIST_SEARCHTABLE_EDIT,
  PAGESTEMPLATELIST_SEARCHTABLE_SEARCH,
} from '../constants/actionTypes'

export default (state = {}, action) => {
  switch (action.type) {
    case PAGESTEMPLATELIST_FETCHITEMS_LOAD:
      return { ...state, pageId: action.pageId, isFetchItemsLoading: true, fetchItemsError: null }
    case PAGESTEMPLATELIST_FETCHITEMS_SUCCEED:
      return {
        ...state,
        isFetchItemsLoading: false,
        items: action.items,
        uiTitle: action.uiTitle,
      }
    case PAGESTEMPLATELIST_FETCHITEMS_FAIL:
      return {
        ...state,
        isFetchItemsLoading: false,
        items: null,
        fetchItemsError: action.fetchItemsError,
      }
    default:
      return state
  }
}
