import moment from 'moment'
import * as ActionTypes from '../constants/actionTypes'
import api from '../utils/api'
import getFieldFromItem, { fieldLang, getFieldWithLangKey, getNewObjFromDirtyLangObj } from '../utils/getFieldFromItem'

export const keys = []

export const editForm = formFieldsChange => ({
  type: ActionTypes.MEMBERFORM_EDITFORM_CHANGE,
  field: formFieldsChange,
})

export const reset = () => ({
  type: ActionTypes.MEMBERFORM_ENTER_RESET,
})

export const fetchItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.MEMBERFORM_FETCHITEM_LOAD })
  try {
    let item = await api.get(`members/${params.id}`)
    item = {
      ...item,
      ...getFieldWithLangKey({ fieldTitle: 'title', objString: item.title, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'first_name', objString: item.first_name, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'last_name', objString: item.last_name, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'secretary', objString: item.secretary, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'preview_info', objString: item.preview_info, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'profile', objString: item.profile, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'pq_extraData', objString: item.pq_extraData, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'pq_mediator_qualification', objString: item.pq_mediator_qualification, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'pq_arbitrator_qualification', objString: item.pq_arbitrator_qualification, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'languages', objString: item.languages, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'extra_listings', objString: item.extra_listings, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'member_short_bio', objString: item.member_short_bio, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'leading_statement', objString: item.leading_statement, languages: fieldLang }),
      startDate: moment(item.startDate),
    }
    dispatch({
      type: ActionTypes.MEMBERFORM_FETCHITEM_SUCCEED,
      item,
    })
    const field = getFieldFromItem(item)
    dispatch({
      type: ActionTypes.MEMBERFORM_EDITFORM_CHANGE,
      field,
    })
  } catch (error) {
    dispatch({
      type: ActionTypes.MEMBERFORM_FETCHITEM_FAIL,
      fetchItemError: error.message,
    })
  }
}

export const createItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.MEMBERFORM_CREATEITEM_LOAD })
  try {
    let obj = getNewObjFromDirtyLangObj({ fieldTitle: 'title', languages: fieldLang, obj: params })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'first_name', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'last_name', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'preview_info', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'secretary', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'profile', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'pq_extraData', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'pq_mediator_qualification', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'pq_arbitrator_qualification', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'languages', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'extra_listings', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'member_short_bio', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'leading_statement', languages: fieldLang, obj })
    obj.startDate = moment(obj.startDate).format('YYYY-MM-DD')
    await api.create(`members`, obj)
    dispatch({ type: ActionTypes.MEMBERLIST_CHANGETABLE_CHANGE })
    dispatch({ type: ActionTypes.MEMBERFORM_CREATEITEM_SUCCEED })
    dispatch({ type: ActionTypes.MEMBERFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.MEMBERFORM_CREATEITEM_FAIL,
      createItemError: error.message,
    })
  }
}

export const editItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.MEMBERFORM_EDITITEM_LOAD })
  try {
    let obj = getNewObjFromDirtyLangObj({ fieldTitle: 'title', languages: fieldLang, obj: params })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'first_name', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'last_name', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'preview_info', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'secretary', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'profile', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'pq_extraData', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'pq_mediator_qualification', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'pq_arbitrator_qualification', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'languages', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'extra_listings', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'member_short_bio', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'leading_statement', languages: fieldLang, obj })
    obj.startDate = moment(obj.startDate).format('YYYY-MM-DD')

    await api.patch(`members/${params.id}`, obj)
    dispatch({ type: ActionTypes.MEMBERFORM_EDITITEM_SUCCEED })
  } catch (error) {
    dispatch({
      type: ActionTypes.MEMBERFORM_EDITITEM_FAIL,
      editItemError: error.message,
    })
  }
}

export const deleteItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.MEMBERFORM_DELETEITEM_LOAD })
  try {
    await api.delete(`members/${params.id}`)
    dispatch({ type: ActionTypes.MEMBERFORM_DELETEITEM_SUCCEED })
    dispatch({ type: ActionTypes.MEMBERFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.MEMBERFORM_DELETEITEM_FAIL,
      deleteItemError: error.message,
    })
  }
}
