import React from 'react';

import ColorPicker from '../Colorpicker'
import DropDown from './DropDown'

function DropdownColorPicker(props) {
  const {
    disabled = false,
    stopCloseOnClickSelf = true,
    color,
    onChange,
    setPopup,
    ...rest
  } = props

  return React.createElement(
    DropDown,
    {
      ...rest,
      disabled: disabled,
      stopCloseOnClickSelf: stopCloseOnClickSelf,
      setPopup: setPopup,
    },
    React.createElement(ColorPicker, { value: color, onChange: onChange })
  );
}

export default DropdownColorPicker;