/* eslint-disable import/no-duplicates */
import React from 'react'
import { Table } from 'antd'
import { TableRowEditButton } from '../../components/AppButton'
import { FormattedDate } from '../../components/FormattedDate'
// eslint-disable-next-line import/no-named-as-default
import FormattedDateTime from '../../components/FormattedDate'

class ItemListTable extends React.Component {
  render() {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 10,
        sorter: true,
      }, {
        title: 'Order',
        dataIndex: 'order',
        key: 'order',
        sorter: true,
      }, {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: text => <FormattedDate text={text} />,
        sorter: true,
        width: 100,
      }, {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
      }, {
        title: 'Header',
        dataIndex: 'header',
        key: 'header',
      }, {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        render: text => <FormattedDateTime text={text} />,
      }, {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 100,
        render: (text, record) => (
          <span>
            <TableRowEditButton to={`${this.props.editPath}/${record.id}`} />
          </span>
        ),
      },
    ]
    return (
      <Table
        rowKey={record => `item-row-${record.id}`}
        columns={columns}
        size='small'
        scroll={{ x: 900 }}
        {...this.props}
      />
    )
  }
}

export default ItemListTable
