import React from 'react'
import { Menu, Icon } from 'antd'

export default props => (
  <Menu {...props}>
    <Menu.Item key='dashboard'><Icon type='pie-chart' /><span>Dashboard</span></Menu.Item>
    <Menu.Item key='pages'><Icon type='file' /><span>Pages</span></Menu.Item>
    <Menu.Item key='events'><Icon type='like' /><span>Quotes</span></Menu.Item>
    <Menu.Item key='practice_areas'><Icon type='bars' /><span>Practice Areas</span></Menu.Item>
    <Menu.Item key='members'><Icon type='contacts' /><span>Members</span></Menu.Item>
    <Menu.Item key='administrators'><Icon type='user' /><span>Administrators</span></Menu.Item>
    <Menu.Item key='news'><Icon type='notification' /><span>News</span></Menu.Item>
    <Menu.Item key='assets'><Icon type='solution' /><span>Assets</span></Menu.Item>
  </Menu>
)