import React from 'react'
import { connect } from 'react-redux'
import { Card } from 'antd'
import AdminLayout from '../../components/AdminLayout'
import SectionHeader from '../../components/SectionHeader'
import SectionHeaderTemplate from '../../components/SectionHeaderTemplate'
import SectionContent from '../../components/SectionContent'
import api from '../../utils/api'
import { logout } from '../../actions/app'

const pageTitle = 'Dashboard'

class Dashboard extends React.Component {
 
  async componentDidMount() {
    const handleAppLogOut = () => { this.props.handleAppLogOut() }
    try {
      await api.get('administrators')
    } catch (err) {
      if (err.message === 'This is unauthorised.') {
        handleAppLogOut()
      }
    }
  }

  render() {
    return (
      <AdminLayout>
        <SectionHeader>
          <SectionHeaderTemplate
            breadcrumbRoutes={[{ path: '/admin', title: 'Home' }, { title: pageTitle }]}
            title={pageTitle}
          />
        </SectionHeader>
        <SectionContent>
          <h2>Welcome</h2>
          This website is prowered by Voyager Tech, for support, please contact <a href="mailto:anthony@rocketvoyager.com">anthony@rocketvoyager.com</a>
          <br />
          <br />
          <h3>Pages</h3>
          You can control different sub page here, for example, to enable or disable a page entirely to the public.<br />
          You may also rename the page title here.
          <br />
          <br />
          <h3>Template Engine</h3>
          Our static page are controlled by a template engine, user may edit the page content, and/or edit the layout with our provided templates.
        </SectionContent>
      </AdminLayout>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  handleAppLogOut: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
