import React from 'react'

class FormattedBool extends React.PureComponent {
  render() {
    let { bool } = this.props
    bool = this.props.rev ? !bool : bool
    return (
      <React.Fragment>
        {bool ? '✅' : '❌'}
      </React.Fragment>
    )
  }
}

export default FormattedBool