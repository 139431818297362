import React from 'react'
import { Form, Input, Button, Modal, InputNumber, Checkbox, Select } from 'antd'
import ImageUpload from '../../components/ImageUpload'
import Colorpicker from '../../components/Colorpicker'
import { keys } from '../../actions/pageTemplateForm'

import MultiLanguageField from '../../components/MultiLanguageField'
import V2Editor from '../../components/V2Editor'
import Editor from '../../components/LexicalEditor'

const FormItem = Form.Item
const { TextArea } = Input

class ItemForm extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleOnDelete = this.handleOnDelete.bind(this)
    this.state = {
      editing: {}
    }
  }

  setEditing = (key) => {
    console.log('setEditing', key)
    this.setState({
      editing: {
        [key]: true,
      }
    })
  }

  handleOnSubmit(e) {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return
      }
      this.props.onSubmit({
        ...values,
        template_name: 'v2-carousel',
        template_elements: {
          anchor: true,
          fullSize: true,
          backgroundColor: true,
          alt: true,
          imgSrc: true,
          imgPosition: true,
          imageMaxHeight: true,
          imageMaxWidth: true,
          imgObjectPosition: true,
          child: {
            'en-US': true,
            'zh-CN': true,
            'zh-HK': true,
          },
        },
      })
    })
  }

  handleOnDelete(e) {
    e.preventDefault()
    const handleOnOk = () => { this.props.onDelete() }
    Modal.confirm({
      title: 'Delete Item',
      content: 'Are you sure to delete this item?',
      okType: 'danger',
      onOk() {
        handleOnOk()
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
        md: { span: 10 },
      },
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 12,
          offset: 7,
        },
      },
    }
    const isCreateForm = this.props.type === 'create'
    return (
      <Form onSubmit={this.handleOnSubmit}>
        <FormItem label='Disable?' {...formItemLayout}>
          {getFieldDecorator('disable', {
            valuePropName: 'checked',
          })(<Checkbox autoComplete='off' />)}
        </FormItem>
        <FormItem label='URL Anchor (Optional)' {...formItemLayout}>
          {getFieldDecorator('anchor', {
          })(<Input />)}
        </FormItem>
        <FormItem label='Order' {...formItemLayout}>
          {getFieldDecorator('order', {
            initialValue: 1,
            rules: [{ required: true, message: 'Please input a valid value.' }],
          })(<InputNumber />)}
        </FormItem>
        <FormItem label='background color (image and content)' {...formItemLayout}>
          {getFieldDecorator('backgroundColor', {
            initialValue: '#fff',
          })(<Colorpicker />)}
        </FormItem>
        <FormItem label='Image' {...formItemLayout}>
          {getFieldDecorator('imgSrc', {
          })(<ImageUpload />)}
        </FormItem>
        <FormItem label='Image alt text (for search engine)' {...formItemLayout}>
          {getFieldDecorator('alt', {
            initialValue: '',
            rules: [{ message: 'Please input a valid value.' }],
          })(<Input />)}
        </FormItem>
        <FormItem label='Image Position' {...formItemLayout}>
          {getFieldDecorator('imgPosition', {
            initialValue: 'left',
            rules: [{ required: true, message: 'Please input a valid value.' }],
          })(<Select>
            <Select.Option value='left'>Left</Select.Option>
            <Select.Option value='right'>Right</Select.Option>
             </Select>)}
        </FormItem>
        <FormItem label='Image Max Height (css)' {...formItemLayout}>
          {getFieldDecorator('imgMaxHeight', {
            initialValue: '100%',
            rules: [{ required: true, message: 'Please input a valid value.' }],
          })(<Input />)}
        </FormItem>
        <FormItem label='Image Max Width (css)' {...formItemLayout}>
          {getFieldDecorator('imgMaxWidth', {
            initialValue: '100%',
            rules: [{ required: true, message: 'Please input a valid value.' }],
          })(<Input />)}
        </FormItem>
        <FormItem label='Image Object Position (css)' {...formItemLayout}>
          {getFieldDecorator('imgObjectPosition', {
            initialValue: '50% 50%',
            rules: [{ required: true, message: 'Please input a valid value.' }],
          })(<Input />)}
        </FormItem>
        <FormItem label='Content take entire width?' {...formItemLayout}>
          {getFieldDecorator('fullSize', {
            valuePropName: 'checked',
          })(<Checkbox autoComplete='off' />)}
        </FormItem>
        <FormItem label='Content'>
          <V2Editor
            label='Content'
            field='child'
            formItemLayout={formItemLayout}
            getFieldDecorator={getFieldDecorator}
            backgroundColor={this.props.form.getFieldValue('backgroundColor')}
            // setEditing={this.setEditing}
            // editing={this.state.editing}
          />
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          <Button
            loading={this.props.isCreateItemLoading || this.props.isEditItemLoading}
            type='primary'
            htmlType='submit'
            style={{ marginRight: '12px' }}
          >
            {isCreateForm ? 'Create' : 'Save'}
          </Button>
          {
            !isCreateForm &&
            <Button
              loading={this.props.isDeleteItemLoading}
              type='danger'
              htmlType='button'
              onClick={this.handleOnDelete}
            >
              Delete
            </Button>
          }
        </FormItem>
      </Form>
    )
  }
}

const CustomizedForm = Form.create({
  onFieldsChange(props, changedFields) {
    // if (Object.keys(changedFields)[0] === 'child') { return }
    props.onFieldsChange(changedFields)
  },
  mapPropsToFields(props) {
    const { formFieldValues = {} } = props
    const ks = keys.length === 0 ? Object.keys(formFieldValues) : keys
    const fields = ks.reduce((prev, key) => (
      { ...prev, [key]: Form.createFormField(formFieldValues[key]) }
    ), {})
    return fields
  },
  // onValuesChange(_, values) {
  //   console.log(values)
  // },
})(ItemForm)

export default CustomizedForm
