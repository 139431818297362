/* eslint-disable import/first */
import React, { useState, useEffect, useReducer } from 'react'

import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { ListItemNode, ListNode } from '@lexical/list'
import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { $createParagraphNode, $getRoot, $getSelection, $insertNodes, BLUR_COMMAND, CLICK_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND, KEY_DOWN_COMMAND } from 'lexical'
import { TRANSFORMERS } from '@lexical/markdown'

import './Lex/style.css'

// import TreeViewPlugin from './plugins/TreeViewPlugin'
import ExampleTheme from './Lex/theme'
import ToolbarPlugin from './Lex/ToolBarPlugin'
import AutoLinkPlugin from './Lex/AutoLinkPlugin'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import ToolbarAdvancedPlugin from './Lex/ToolBarAdvancedPlugin'
import MentionNodePlugin from './Lex/MentionNodePlugin/index.jsx'
import { MentionNode } from './Lex/MentionNode'

function Placeholder() {
  return <div className='editor-placeholder'>Enter some rich text...</div>
}

export default function Editor({
  value,
  onChange,
  backgroundColor,
  editing = false,
  setPopup,
}) {
  console.log(value)
  console.log(typeof value)

  const initEditorState = {
    theme: ExampleTheme,
    onError(error) {
      throw error
    },
    editorState: value,
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      MentionNode,
    ],
  }

  const eOnChange = (editorState, editor) => {
    // eslint-disable-next-line no-underscore-dangle
    editorState.read(() => {
      const currentState = JSON.stringify(editor.getEditorState())
      if (currentState !== value) {
        onChange(currentState)
      }
    })
  }


  let key = 1
  if (value === '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}') {
    key = value
  }

  if (value) {
    return (<LexicalComposer key={key} initialConfig={initEditorState}>
      <OnChangePlugin onChange={eOnChange} />
      <div
        className='editor-container'
        style={{
        backgroundColor,
      }}
      >
        {/* <ToolbarPlugin /> */}
        <ToolbarAdvancedPlugin setPopup={setPopup} />
        <div className='editor-inner'>
          <RichTextPlugin
            contentEditable={<ContentEditable className='editor-input' />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          {/* <TreeViewPlugin /> */}
          <AutoFocusPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <MentionNodePlugin />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
      </div>
    </LexicalComposer>)
  }
  return <></>
}
