import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import PrivateRoute from '../../components/PrivateRoute'
import Login from '../Login'
import NotFound from '../NotFound'
import Dashboard from '../Dashboard'
import PageList from '../PageList'
import PageForm from '../PageForm'
import PracticeAreaList from '../PracticeAreaList'
import PracticeAreaForm from '../PracticeAreaForm'
import AdministratorList from '../AdministratorList'
import AdministratorForm from '../AdministratorForm'
import PageTemplateList from '../PageTemplateList'
import PageTemplateForm from '../PageTemplateForm'
import MemberList from '../MemberList'
import MemberForm from '../MemberForm'
import CvAppointmentsAndPublicOfficeList from '../CvAppointmentsAndPublicOfficeList'
import CvAppointmentsAndPublicOfficeForm from '../CvAppointmentsAndPublicOfficeForm'
import CvEducationList from '../CvEducationList'
import CvEducationForm from '../CvEducationForm'
import CvPublicationsAndArticleList from '../CvPublicationsAndArticleList'
import CvPublicationsAndArticleForm from '../CvPublicationsAndArticleForm'
import CvPracticeAreaList from '../CvPracticeAreaList'
import CvPracticeAreaForm from '../CvPracticeAreaForm'
import CvSelectedCaseList from '../CvSelectedCaseList'
import CvSelectedCaseForm from '../CvSelectedCaseForm'
import CvSelectedCasesCaseList from '../CvSelectedCasesCaseList'
import CvSelectedCasesCaseForm from '../CvSelectedCasesCaseForm'
import CvScholarshipAndPrizeList from '../CvScholarshipAndPrizeList'
import CvScholarshipAndPrizeForm from '../CvScholarshipAndPrizeForm'
import NewList from '../NewList'
import NewForm from '../NewForm'
import EventList from '../EventList'
import EventForm from '../EventForm'
import AssetList from '../AssetList'
import AssetForm from '../AssetForm'
import './index.less'

export default class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={() => <Redirect to='/login' />} />
          <Route exact path='/login' component={Login} />
          <PrivateRoute exact path='/admin' component={() => <Redirect to='/admin/dashboard' />} />
          <PrivateRoute exact path='/admin/dashboard' component={Dashboard} />
          <PrivateRoute exact path='/admin/pages' component={PageList} />
          <PrivateRoute exact path='/admin/pages/create' component={props => <PageForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/pages/edit/:itemId' component={props => <PageForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/page/t/:pageId' component={props => <PageTemplateList {...props} />} />
          <PrivateRoute exact path='/admin/page/t/:pageId/create' component={props => <PageTemplateForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/page/t/:pageId/edit/:templateId' component={props => <PageTemplateForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/practice_areas' component={PracticeAreaList} />
          <PrivateRoute exact path='/admin/practice_areas/create' component={props => <PracticeAreaForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/practice_areas/edit/:itemId' component={props => <PracticeAreaForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/administrators' component={AdministratorList} />
          <PrivateRoute exact path='/admin/administrators/create' component={props => <AdministratorForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/administrators/edit/:itemId' component={props => <AdministratorForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/members' component={MemberList} />
          <PrivateRoute exact path='/admin/members/create' component={props => <MemberForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/members/edit/:itemId' component={props => <MemberForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_appointments_and_public_offices' component={props => <CvAppointmentsAndPublicOfficeList {...props} />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_appointments_and_public_offices/create' component={props => <CvAppointmentsAndPublicOfficeForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_appointments_and_public_offices/edit/:itemId' component={props => <CvAppointmentsAndPublicOfficeForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_educations' component={props => <CvEducationList {...props} />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_educations/create' component={props => <CvEducationForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_educations/edit/:itemId' component={props => <CvEducationForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_publications_and_articles' component={props => <CvPublicationsAndArticleList {...props} />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_publications_and_articles/create' component={props => <CvPublicationsAndArticleForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_publications_and_articles/edit/:itemId' component={props => <CvPublicationsAndArticleForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_practice_areas' component={props => <CvPracticeAreaList {...props} />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_practice_areas/create' component={props => <CvPracticeAreaForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_practice_areas/edit/:itemId' component={props => <CvPracticeAreaForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_selected_cases' component={props => <CvSelectedCaseList {...props} />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_selected_cases/create' component={props => <CvSelectedCaseForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_selected_cases/edit/:itemId' component={props => <CvSelectedCaseForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_selected_cases/:casesId/cases' component={props => <CvSelectedCasesCaseList {...props} />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_selected_cases/:casesId/cases/create' component={props => <CvSelectedCasesCaseForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_selected_cases/:casesId/cases/edit/:itemId' component={props => <CvSelectedCasesCaseForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_scholarship_and_prizes' component={props => <CvScholarshipAndPrizeList {...props} />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_scholarship_and_prizes/create' component={props => <CvScholarshipAndPrizeForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/members/:memberId/cv_scholarship_and_prizes/edit/:itemId' component={props => <CvScholarshipAndPrizeForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/news' component={NewList} />
          <PrivateRoute exact path='/admin/news/create' component={props => <NewForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/news/edit/:itemId' component={props => <NewForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/events' component={EventList} />
          <PrivateRoute exact path='/admin/events/create' component={props => <EventForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/events/edit/:itemId' component={props => <EventForm {...props} type='edit' />} />
          <PrivateRoute exact path='/admin/assets' component={AssetList} />
          <PrivateRoute exact path='/admin/assets/create' component={props => <AssetForm {...props} type='create' />} />
          <PrivateRoute exact path='/admin/assets/edit/:itemId' component={props => <AssetForm {...props} type='edit' />} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    )
  }
}
