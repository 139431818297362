import * as ActionTypes from '../constants/actionTypes'
import api from '../utils/api'
import getFieldFromItem, { fieldLang, getFieldWithLangKey, getNewObjFromDirtyLangObj } from '../utils/getFieldFromItem'

export const keys = []

export const editForm = formFieldsChange => ({
  type: ActionTypes.PAGETEMPLATEFORM_EDITFORM_CHANGE,
  field: formFieldsChange,
})

export const reset = () => ({
  type: ActionTypes.PAGETEMPLATEFORM_ENTER_RESET,
})

export const fetchItem = params => (dispatch) => {
  dispatch({ type: ActionTypes.PAGETEMPLATEFORM_FETCHITEM_LOAD })
  let item = params.pageContent
  item = { ...item, ...params.pageContent.template_elements }
  Object.keys(params.pageContent.template_elements).map((k) => {
    if (params.pageContent.template_elements[k] && params.pageContent.template_elements[k]['en-US']) {
      item = { ...item, ...getFieldWithLangKey({ fieldTitle: k, objString: item[k], languages: fieldLang, parse: false }) }
    }
  })
  dispatch({
    type: ActionTypes.PAGETEMPLATEFORM_FETCHITEM_SUCCEED,
    item,
  })
  const field = getFieldFromItem(item)
  dispatch({
    type: ActionTypes.PAGETEMPLATEFORM_EDITFORM_CHANGE,
    field,
  })
}

export const createItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.PAGETEMPLATEFORM_CREATEITEM_LOAD })
  let templateElements = {}
  Object.keys(params.template_elements).map((k) => {
    if (params.template_elements[k]['en-US']) {
      templateElements = {
        ...templateElements,
        ...getNewObjFromDirtyLangObj({
          fieldTitle: k,
          obj: params,
          languages: fieldLang,
          parse: false,
          pure: true,
        }),
      }
    } else if (params[k] || params[k] === 0) {
      templateElements = {
        ...templateElements,
        [k]: params[k],
      }
    }
  })
  const item = {
    disable: params.disable,
    order: params.order,
    template_elements: JSON.stringify(templateElements),
    templateName: params.template_name,
  }
  try {
    await api.create(`pages/${params.pageId}/t`, item)
    dispatch({ type: ActionTypes.PAGESTEMPLATELIST_CHANGETABLE_CHANGE })
    dispatch({ type: ActionTypes.PAGETEMPLATEFORM_CREATEITEM_SUCCEED })
    dispatch({ type: ActionTypes.PAGETEMPLATEFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.PAGETEMPLATEFORM_CREATEITEM_FAIL,
      createItemError: error.message,
    })
  }
}

export const editItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.PAGETEMPLATEFORM_EDITITEM_LOAD })
  let templateElements = {}
  Object.keys(params.template_elements).map((k) => {
    console.log(k)
    console.log(params[k]);
    if (params.template_elements[k] && params.template_elements[k]['en-US']) {
      templateElements = {
        ...templateElements,
        ...getNewObjFromDirtyLangObj({
          fieldTitle: k,
          obj: params,
          languages: fieldLang,
          parse: false,
          pure: true,
        }),
      }
    } else if (params[k] || params[k] === 0) {
      templateElements = {
        ...templateElements,
        [k]: params[k],
      }
    }
  })
  const item = {
    disable: params.disable,
    order: params.order,
    template_elements: JSON.stringify(templateElements),
    ui_id: params.ui_id,
    templates_id: params.templates_id,
  }
  try {
    await api.patch(`pages/${params.ui_id}/t/${params.templates_id}`, item)
    dispatch({ type: ActionTypes.PAGETEMPLATEFORM_EDITITEM_SUCCEED })
  } catch (error) {
    dispatch({
      type: ActionTypes.PAGETEMPLATEFORM_EDITITEM_FAIL,
      editItemError: error.message,
    })
  }
}

export const deleteItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.PAGETEMPLATEFORM_DELETEITEM_LOAD })
  try {
    await api.delete(`pages/${params.pageId}/t/${params.templateId}`)
    dispatch({ type: ActionTypes.PAGETEMPLATEFORM_DELETEITEM_SUCCEED })
    dispatch({ type: ActionTypes.PAGETEMPLATEFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.PAGETEMPLATEFORM_DELETEITEM_FAIL,
      deleteItemError: error.message,
    })
  }
}

export const fetchTemplate = params => async (dispatch) => {
  try {
    const pageContent = await api.get(`pages/${params.pageId}/t/${params.templateId}`)
    dispatch({
      type: ActionTypes.PAGESTEMPLATEFORM_ENTER_PREPARE_SUCCEED,
      templateName: pageContent.name,
      title: pageContent.title,
    })
    if (params.templateId) {
      dispatch(fetchItem({ pageContent, templateName: pageContent.name }))
    }
  } catch (error) {
    dispatch({
      type: ActionTypes.PAGETEMPLATEFORM_FETCHITEM_FAIL,
      fetchItemError: error.message,
    })
  }
}
