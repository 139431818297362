/* eslint-disable no-mixed-operators */
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import { message, Select, Row, Col } from 'antd'
import AdminLayout from '../../components/AdminLayout'
import SectionHeader from '../../components/SectionHeader'
import SectionHeaderTemplate from '../../components/SectionHeaderTemplate'
import SectionContent from '../../components/SectionContent'
import Spin from '../../components/Spin'
import HeroTextForm from '../templateEngine/HeroText'
import HeroPicForm from '../templateEngine/HeroPic'
import ContentPicForm from '../templateEngine/ContentPic'
import Freedom from '../templateEngine/Freedom'
import Carousel from '../templateEngine/Carousel'
import Contact from '../templateEngine/Contact'
import {
  fetchTemplate,
  editForm,
  fetchItem,
  createItem,
  editItem,
  deleteItem,
  reset,
} from '../../actions/pageTemplateForm'
import { Introduction } from '../templateEngine/Introduction'



const Option = Select.Option;

const listPath = pageId => `/admin/page/t/${pageId}`
const pageTitle = pageName => `Page / ${pageName}`
const templateTitle = (templateName, pageName) => templateName ? `Page / ${pageName} <${templateName}>` : `Page / ${pageName}`
const storeKey = 'pageTemplateForm'

class ItemForm extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleFormOnSubmit = this.handleFormOnSubmit.bind(this)
    this.handleFormOnDelete = this.handleFormOnDelete.bind(this)
    this.handleTemplateChange = this.handleTemplateChange.bind(this)
    this.state = {
      selectedTemplate: null,
    }
  }

  componentWillMount() {
    this.props.reset()
    this.setState({
      selectedTemplate: this.props.templateName,
      // selectedTemplate: ''
    })
  }

  async componentDidMount() {
    if (this.props.type === 'edit') {
      await this.props.fetchTemplate({ pageId: this.props.match.params.pageId, templateId: this.props.match.params.templateId })
    } else {
      await this.props.fetchTemplate({ pageId: this.props.match.params.pageId, templateId: false })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isCreateItemLoading && nextProps.isCreateItemSuccess) {
      message.success('You have successfully created the item.')
    } else if (this.props.isEditItemLoading && nextProps.isEditItemSuccess) {
      message.success('You have successfully edited the item.')
    } else if (this.props.isDeleteItemLoading && nextProps.isDeleteItemSuccess) {
      message.success('You have successfully deleted the item.')
    } else if (this.props.isCreateItemLoading && nextProps.createItemError) {
      message.error(nextProps.createItemError)
    } else if (this.props.isEditItemLoading && nextProps.editItemError) {
      message.error(nextProps.editItemError)
    } else if (this.props.isDeleteItemLoading && nextProps.deleteItemError) {
      message.error(nextProps.deleteItemError)
    } else if (this.props.isFetchItemLoading && nextProps.fetchItemError) {
      message.error(nextProps.fetchItemError)
    }
  }

  handleFormOnSubmit(values) {
    if (this.props.type === 'create') {
      this.props.createItem({ ...values, pageId: this.props.match.params.pageId })
    } else {
      const item = { ...this.props.item, ...values }
      this.props.editItem(item)
    }
  }

  handleFormOnDelete() {
    this.props.deleteItem({ pageId: this.props.match.params.pageId, templateId: this.props.match.params.templateId })
  }

  handleTemplateChange(value) {
    this.setState({
      selectedTemplate: value
    })
  }

  render() {
    const { title, templateName, type, isCreateItemSuccess, isDeleteItemSuccess, isTemplateReady } = this.props
    const isCreateForm = type === 'create'
    const actionTitle = isCreateForm ? 'Create' : 'Edit'
    if (isCreateItemSuccess || isDeleteItemSuccess) {
      return <Redirect to={listPath(this.props.match.params.pageId)} />
    }
    if (isTemplateReady) {
      return (
        <div>
          <AdminLayout>
            <SectionHeader>
              <SectionHeaderTemplate
                breadcrumbRoutes={[{ path: '/admin', title: 'Home' }, { path: listPath(this.props.match.params.pageId), title: pageTitle(title['en-US']) }, { title: actionTitle }]}
                title={`${actionTitle} ${templateTitle(templateName, title['en-US'])}`}
              />
            </SectionHeader>
            {!this.props.templateName &&
              <SectionHeader>
                <h2>Choose Template</h2>
                <Row gutter={12}>
                  <Col span={6} >
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="Select a template"
                      optionFilterProp="children"
                      onChange={this.handleTemplateChange}
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      <Option value="hero-text">Hero Text</Option>
                      <Option value="hero-pic">Hero Pic</Option>
                      <Option value="content-pic">Content Pic</Option>

                      <Option value="v2-carousel">V2 - Carousell</Option>
                      <Option value="v2-contact">V2 - Contact US</Option>
                      <Option value="v2-freedom">V2 - Freedom</Option>
                    </Select>
                  </Col>
                  <Col span={6} >
                    <Introduction templateName={this.state.selectedTemplate} />
                  </Col>
                </Row>
              </SectionHeader>
            }
            <SectionContent>
              {
                this.props.isFetchItemLoading && <Spin />
              }
              {
                (isCreateForm || (!isCreateForm && this.props.item)) &&
                this.state.selectedTemplate === 'hero-text' &&
                <HeroTextForm
                  onSubmit={this.handleFormOnSubmit}
                  onDelete={this.handleFormOnDelete}
                  onFieldsChange={this.props.editForm}
                  formFieldValues={this.props.formFieldValues}
                  isCreateItemLoading={this.props.isCreateItemLoading}
                  isEditItemLoading={this.props.isEditItemLoading}
                  isDeleteItemLoading={this.props.isDeleteItemLoading}
                  type={this.props.type}
                // eslint-disable-next-line no-mixed-operators
                /> ||
                this.state.selectedTemplate === 'content-pic' &&
                <ContentPicForm
                  onSubmit={this.handleFormOnSubmit}
                  onDelete={this.handleFormOnDelete}
                  onFieldsChange={this.props.editForm}
                  formFieldValues={this.props.formFieldValues}
                  isCreateItemLoading={this.props.isCreateItemLoading}
                  isEditItemLoading={this.props.isEditItemLoading}
                  isDeleteItemLoading={this.props.isDeleteItemLoading}
                  type={this.props.type}
                /> ||
                this.state.selectedTemplate === 'hero-pic' &&
                <HeroPicForm
                  onSubmit={this.handleFormOnSubmit}
                  onDelete={this.handleFormOnDelete}
                  onFieldsChange={this.props.editForm}
                  formFieldValues={this.props.formFieldValues}
                  isCreateItemLoading={this.props.isCreateItemLoading}
                  isEditItemLoading={this.props.isEditItemLoading}
                  isDeleteItemLoading={this.props.isDeleteItemLoading}
                  type={this.props.type}
                /> ||
                this.state.selectedTemplate === 'v2-carousel' &&
                <Carousel
                  onSubmit={this.handleFormOnSubmit}
                  onDelete={this.handleFormOnDelete}
                  onFieldsChange={this.props.editForm}
                  formFieldValues={this.props.formFieldValues}
                  isCreateItemLoading={this.props.isCreateItemLoading}
                  isEditItemLoading={this.props.isEditItemLoading}
                  isDeleteItemLoading={this.props.isDeleteItemLoading}
                  type={this.props.type}
                /> ||
                this.state.selectedTemplate === 'v2-freedom' &&
                <Freedom
                  onSubmit={this.handleFormOnSubmit}
                  onDelete={this.handleFormOnDelete}
                  onFieldsChange={this.props.editForm}
                  formFieldValues={this.props.formFieldValues}
                  isCreateItemLoading={this.props.isCreateItemLoading}
                  isEditItemLoading={this.props.isEditItemLoading}
                  isDeleteItemLoading={this.props.isDeleteItemLoading}
                  type={this.props.type}
                /> ||
                this.state.selectedTemplate === 'v2-contact' &&
                <Contact
                  onSubmit={this.handleFormOnSubmit}
                  onDelete={this.handleFormOnDelete}
                  onFieldsChange={this.props.editForm}
                  formFieldValues={this.props.formFieldValues}
                  isCreateItemLoading={this.props.isCreateItemLoading}
                  isEditItemLoading={this.props.isEditItemLoading}
                  isDeleteItemLoading={this.props.isDeleteItemLoading}
                  type={this.props.type}
                /> ||
                <div>Please select a template</div>
              }
            </SectionContent>
          </AdminLayout>
        </div>
      )
    }
    return <AdminLayout><Spin /></AdminLayout>
  }
}

const mapStateToProps = (state) => {
  const {
    title,
    templateName,
    isTemplateReady,
    formFieldValues,
    isFetchItemLoading,
    isEditItemLoading,
    isCreateItemLoading,
    isCreateItemSuccess,
    isEditItemSuccess,
    isDeleteItemLoading,
    isDeleteItemSuccess,
    item,
    fetchItemError,
    createItemError,
    editItemError,
    deleteItemError,
  } = state[storeKey]
  return {
    title,
    templateName,
    isTemplateReady,
    isFetchItemLoading,
    formFieldValues,
    isEditItemLoading,
    isCreateItemLoading,
    isCreateItemSuccess,
    isEditItemSuccess,
    isDeleteItemLoading,
    isDeleteItemSuccess,
    item,
    fetchItemError,
    createItemError,
    editItemError,
    deleteItemError,
  }
}

const mapDispatchToProps = dispatch => ({
  createItem: params => dispatch(createItem(params)),
  editItem: params => dispatch(editItem(params)),
  fetchItem: params => dispatch(fetchItem(params)),
  deleteItem: params => dispatch(deleteItem(params)),
  editForm: formFieldsChange => dispatch(editForm(formFieldsChange)),
  fetchTemplate: params => dispatch(fetchTemplate(params)),
  reset: () => dispatch(reset()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemForm))
