import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const { isLoggedIn } = rest
      return (
        isLoggedIn
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login' }} />
      )
    }}
  />
)

const mapStateToProps = (state) => {
  const isLoggedIn = !!state.app.accessToken
  return { isLoggedIn }
}

export default connect(mapStateToProps)(PrivateRoute)
