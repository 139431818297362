import * as ActionTypes from '../constants/actionTypes'
import api from '../utils/api'
import getFieldFromItem, { fieldLang, getFieldWithLangKey, getNewObjFromDirtyLangObj } from '../utils/getFieldFromItem'

export const keys = []

export const editForm = formFieldsChange => ({
  type: ActionTypes.PRACTICEAREAFORM_EDITFORM_CHANGE,
  field: formFieldsChange,
})

export const reset = () => ({
  type: ActionTypes.PRACTICEAREAFORM_ENTER_RESET,
})

export const fetchItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.PRACTICEAREAFORM_FETCHITEM_LOAD })
  try {
    let item = await api.get(`practice-areas/${params.id}`)
    item = {
      ...item,
      ...getFieldWithLangKey({ fieldTitle: 'name', objString: item.name, languages: fieldLang }),
      ...getFieldWithLangKey({ fieldTitle: 'practice_area_description', objString: item.practice_area_description, languages: fieldLang }),
    }
    dispatch({
      type: ActionTypes.PRACTICEAREAFORM_FETCHITEM_SUCCEED,
      item,
    })
    const field = getFieldFromItem(item)
    dispatch({
      type: ActionTypes.PRACTICEAREAFORM_EDITFORM_CHANGE,
      field,
    })
  } catch (error) {
    dispatch({
      type: ActionTypes.PRACTICEAREAFORM_FETCHITEM_FAIL,
      fetchItemError: error.message,
    })
  }
}

export const createItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.PRACTICEAREAFORM_CREATEITEM_LOAD })
  try {
    await api.create(`practice-areas`,
      {
        ...getNewObjFromDirtyLangObj({ fieldTitle: 'name', languages: fieldLang, obj: params }),
        ...getNewObjFromDirtyLangObj({ fieldTitle: 'practice_area_description', languages: fieldLang, obj: params }),
      },
    )
    dispatch({ type: ActionTypes.PRACTICEAREALIST_CHANGETABLE_CHANGE })
    dispatch({ type: ActionTypes.PRACTICEAREAFORM_CREATEITEM_SUCCEED })
    dispatch({ type: ActionTypes.PRACTICEAREAFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.PRACTICEAREAFORM_CREATEITEM_FAIL,
      createItemError: error.message,
    })
  }
}

export const editItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.PRACTICEAREAFORM_EDITITEM_LOAD })
  try {
    await api.patch(`practice-areas/${params.id}`,
      {
        ...getNewObjFromDirtyLangObj({ fieldTitle: 'name', languages: fieldLang, obj: getNewObjFromDirtyLangObj({ fieldTitle: 'practice_area_description', languages: fieldLang, obj: params }) }),
      },
    )
    dispatch({ type: ActionTypes.PRACTICEAREAFORM_EDITITEM_SUCCEED })
  } catch (error) {
    dispatch({
      type: ActionTypes.PRACTICEAREAFORM_EDITITEM_FAIL,
      editItemError: error.message,
    })
  }
}

export const deleteItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.PRACTICEAREAFORM_DELETEITEM_LOAD })
  try {
    await api.delete(`practice-areas/${params.id}`)
    dispatch({ type: ActionTypes.PRACTICEAREAFORM_DELETEITEM_SUCCEED })
    dispatch({ type: ActionTypes.PRACTICEAREAFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.PRACTICEAREAFORM_DELETEITEM_FAIL,
      deleteItemError: error.message,
    })
  }
}
