import * as ActionTypes from '../constants/actionTypes'
import api from '../utils/api'
import getFieldFromItem from '../utils/getFieldFromItem'

export const keys = []

export const editForm = formFieldsChange => ({
  type: ActionTypes.ASSETFORM_EDITFORM_CHANGE,
  field: formFieldsChange,
})

export const reset = () => ({
  type: ActionTypes.ASSETFORM_ENTER_RESET,
})

export const fetchItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.ASSETFORM_FETCHITEM_LOAD })
  try {
    const item = await api.get(`asset/${params.id}`)
    dispatch({
      type: ActionTypes.ASSETFORM_FETCHITEM_SUCCEED,
      item,
    })
    const field = getFieldFromItem(
      item,
      //keys,
    )
    dispatch({
      type: ActionTypes.ASSETFORM_EDITFORM_CHANGE,
      field,
    })
  } catch (error) {
    dispatch({
      type: ActionTypes.ASSETFORM_FETCHITEM_FAIL,
      fetchItemError: error.message,
    })
  }
}

export const createItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.ASSETFORM_CREATEITEM_LOAD })
  try {
    await api.create(`asset`, params)
    dispatch({ type: ActionTypes.ASSETLIST_CHANGETABLE_CHANGE })
    dispatch({ type: ActionTypes.ASSETFORM_CREATEITEM_SUCCEED })
    dispatch({ type: ActionTypes.ASSETFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.ASSETFORM_CREATEITEM_FAIL,
      createItemError: error.message,
    })
  }
}

export const editItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.ASSETFORM_EDITITEM_LOAD })
  try {
    await api.patch(`asset/${params.id}`, params)
    dispatch({ type: ActionTypes.ASSETFORM_EDITITEM_SUCCEED })
  } catch (error) {
    dispatch({
      type: ActionTypes.ASSETFORM_EDITITEM_FAIL,
      editItemError: error.message,
    })
  }
}

export const deleteItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.ASSETFORM_DELETEITEM_LOAD })
  try {
    await api.delete(`asset/${params.id}`)
    dispatch({ type: ActionTypes.ASSETFORM_DELETEITEM_SUCCEED })
    dispatch({ type: ActionTypes.ASSETFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.ASSETFORM_DELETEITEM_FAIL,
      deleteItemError: error.message,
    })
  }
}
