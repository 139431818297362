import React from 'react'
import { Table } from 'antd'
import { TableRowEditButton } from '../../components/AppButton'
import FormattedDate from '../../components/FormattedDate'

class ItemListTable extends React.Component {
  render() {
    const columns = [
      {
        title: 'Order',
        dataIndex: 'order',
        key: 'order',
      }, {
        title: 'Template Name',
        dataIndex: 'name',
        key: 'name',
      }, {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        render: text => <FormattedDate text={text} />,
      }, {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <TableRowEditButton to={`${this.props.editPath}/${record.templates_id}`} />
          </span>
        ),
      },
    ]
    return (
      <Table
        rowKey={record => `item-row-${record.order}`}
        columns={columns}
        size='big'
        {...this.props}
      />
    )
  }
}

export default ItemListTable
