import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Col, Input, message } from 'antd'
import AdminLayout from '../../components/AdminLayout'
import SectionHeader from '../../components/SectionHeader'
import SectionHeaderTemplate from '../../components/SectionHeaderTemplate'
import SectionContent from '../../components/SectionContent'
import { CreateButton } from '../../components/AppButton'
import Table from './Table'
import {
  fetchItems,
} from '../../actions/pageTemplateList'

const pageTitle = pageName => `Page: ${pageName}`
const storeKey = 'pageTemplateList'
const createPath = pageId => `/admin/page/t/${pageId}/create`
const editPath = pageId => `/admin/page/t/${pageId}/edit`

class ItemList extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.handleCreateButtonOnClick = this.handleCreateButtonOnClick.bind(this)
  }

  componentDidMount() {
    this.props.fetchItems({ pageId: this.props.match.params.pageId })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isFetchItemsLoading && nextProps.fetchItemsError) {
      message.error(nextProps.fetchItemsError)
    }
  }

  handleCreateButtonOnClick(e) {
    e.preventDefault()
    this.props.history.push(createPath(this.props.pageId))
  }

  render() {
    return (
      <div>
        <AdminLayout>
          <SectionHeader>
            <SectionHeaderTemplate
              breadcrumbRoutes={[{ path: `/admin`, title: 'Home' }, { path: `/admin/pages`, title: 'Pages' }, { title: pageTitle(this.props.uiTitle) }]}
              title={pageTitle(this.props.uiTitle)}
            />
          </SectionHeader>
          <SectionContent borderless>
            <Row type='flex' justify='space-between' style={{ marginBottom: '24px' }}>
              <Col>
                <h3>Arrangement</h3>
              </Col>
              <Col>
                <CreateButton
                  onClick={this.handleCreateButtonOnClick}
                  name='Add Template'
                />
              </Col>
            </Row>
            <Table
              loading={this.props.isFetchItemsLoading}
              dataSource={this.props.items || []}
              pagination={false}
              editPath={editPath(this.props.pageId)}
              style={{ margin: 'auto', width: 1000 }}
            />
          </SectionContent>
        </AdminLayout>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    pageId,
    items,
    uiTitle,
    total,
    isFetchItemsLoading,
    fetchItemsError,
  } = state[storeKey]
  return {
    pageId,
    items,
    uiTitle,
    total,
    isFetchItemsLoading,
    fetchItemsError,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchItems: params => dispatch(fetchItems(params)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItemList))
