var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DecoratorNode } from 'lexical';
import React from 'react';
const InlineMemberMentionComponent = (props) => {
    const [name, setName] = React.useState('');
    const [picture, setPicture] = React.useState('');
    const [info, setInfo] = React.useState({});
    const infoPopupRef = React.useRef();
    const pillRef = React.useRef();
    React.useEffect(() => {
        const checkPopupPosition = () => {
            const rect = infoPopupRef.current.getBoundingClientRect();
            if (rect.bottom > window.innerHeight) {
                infoPopupRef.current.style.transform = 'translate(-50%, calc(-100% - 30px))';
            }
            else {
                infoPopupRef.current.style.transform = 'translate(-50%, 10px)';
            }
        };
        const pill = pillRef.current;
        pill.addEventListener('mouseenter', checkPopupPosition);
        pill.addEventListener('mouseleave', () => {
            infoPopupRef.current.style.transform = 'translate(-60%, 0)';
        });
        return () => {
            pill.removeEventListener('mouseenter', checkPopupPosition);
            pill.removeEventListener('mouseleave', () => {
                infoPopupRef.current.style.transform = 'translate(-60%, 0)';
            });
        };
    }, []);
    React.useEffect(() => {
        // fetch api result of name
        function fetchMember(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const member = yield fetch(`https://dev.templechambers.com/api/members/${id}`);
                const memberJson = yield member.json();
                let lang = 'en-US';
                if (!!props.language) {
                    lang = props.language;
                }
                if (lang === 'en-US') {
                    setName(memberJson.memberData.firstName['en-US'] + ' ' + memberJson.memberData.lastName['en-US'] + ' ' + (memberJson.memberData.title['en-US'] ? memberJson.memberData.title['en-US'] : ""));
                }
                else {
                    setName(memberJson.memberData.lastName[lang] + memberJson.memberData.firstName[lang] + (memberJson.memberData.title[lang] ? memberJson.memberData.title[lang] : ""));
                }
                const url = memberJson.memberData.photoUrl;
                const extension = url.split('.').pop();
                const fileName = url.split('.').slice(0, -1).join('.');
                const newUrl = fileName + '_n.' + extension;
                setPicture(newUrl);
                setInfo(memberJson.memberShortBio[lang]);
            });
        }
        fetchMember(props.id);
    }, []);
    return React.createElement("div", { ref: pillRef, className: `memberMention ${props.pill ? "pill" : ""}` },
        React.createElement("img", { src: picture, alt: name, className: "memberImg" }),
        React.createElement("span", null, name),
        React.createElement("div", { className: "infoPopup", ref: infoPopupRef },
            React.createElement("img", { src: picture, alt: name, className: "popupImg" }),
            React.createElement("div", null,
                React.createElement("h4", null, name))));
};
export class MentionNode extends DecoratorNode {
    static getType() {
        return 'mention';
    }
    static clone(node) {
        return new MentionNode(node.__id, node.__NameEN, node.__NameZH_CN, node.__NameZH_HK, node.__pill, node.__key);
    }
    constructor(id, nameEN, nameZH_CN, nameZH_HK, pill = false, key) {
        super(key);
        this.__id = id;
        this.__NameEN = nameEN;
        this.__NameZH_CN = nameZH_CN;
        this.__NameZH_HK = nameZH_HK;
        this.__pill = pill;
    }
    createDOM(config) {
        const div = document.createElement('div');
        div.style.display = 'contents';
        return div;
    }
    updateDOM() {
        return false;
    }
    // setId(id: string): void {
    //   const writable = this.getWritable()
    //   writable.__id = id
    // }
    decorate(editor) {
        return React.createElement(InlineMemberMentionComponent, { id: this.__id, nameEN: this.__NameEN, nameZH_CN: this.__NameZH_CN, nameZH_HK: this.__NameZH_HK, pill: this.__pill });
    }
    exportJSON() {
        return {
            type: MentionNode.getType(),
            version: 1,
            id: this.__id,
            nameEN: this.__NameEN,
            nameZH_CN: this.__NameZH_CN,
            nameZH_HK: this.__NameZH_HK,
            pill: this.__pill,
        };
    }
    static importJSON(serializedNode) {
        const { id, nameEN, nameZH_CN, nameZH_HK, pill } = serializedNode;
        // eslint-disable-next-line no-use-before-define
        const node = $createMentionNode({ id, nameEN, nameZH_CN, nameZH_HK, pill });
        return node;
    }
}
export function $createMentionNode(props) {
    return new MentionNode(props.id, props.nameEN, props.nameZH_CN, props.nameZH_HK, props.pill);
}
export function $isMentionNode(node) {
    return node instanceof MentionNode;
}
