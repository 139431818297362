import * as ActionTypes from '../constants/actionTypes'
import api from '../utils/api'
import getFieldFromItem from '../utils/getFieldFromItem'

export const keys = []

export const editForm = formFieldsChange => ({
  type: ActionTypes.CV_PRACTICE_AREAFORM_EDITFORM_CHANGE,
  field: formFieldsChange,
})

export const reset = () => async (dispatch) => {
  try {
    const paReq = await api.get('practice-areas?$limit=99&$sort[sorting_index]=1&$sort[id]=1')
    const {
      data: pa,
    } = paReq
    dispatch({
      type: ActionTypes.CV_PRACTICE_AREAFORM_ENTER_RESET,
      pa,
    })
  } catch (error) {
    dispatch({
      type: ActionTypes.CV_PRACTICE_AREAFORM_FETCHITEM_FAIL,
      fetchItemError: error.message,
    })
  }
}

export const fetchItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.CV_PRACTICE_AREAFORM_FETCHITEM_LOAD })
  try {
    const item = await api.get(`cv_practice_areas/${params.id}`)
    dispatch({
      type: ActionTypes.CV_PRACTICE_AREAFORM_FETCHITEM_SUCCEED,
      item,
    })
    const field = getFieldFromItem(item)
    dispatch({
      type: ActionTypes.CV_PRACTICE_AREAFORM_EDITFORM_CHANGE,
      field,
    })
  } catch (error) {
    dispatch({
      type: ActionTypes.CV_PRACTICE_AREAFORM_FETCHITEM_FAIL,
      fetchItemError: error.message,
    })
  }
}

export const createItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.CV_PRACTICE_AREAFORM_CREATEITEM_LOAD })
  try {
    await api.create(`cv_practice_areas`, params)
    dispatch({ type: ActionTypes.CV_PRACTICE_AREALIST_CHANGETABLE_CHANGE })
    dispatch({ type: ActionTypes.CV_PRACTICE_AREAFORM_CREATEITEM_SUCCEED })
    dispatch({ type: ActionTypes.CV_PRACTICE_AREAFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.CV_PRACTICE_AREAFORM_CREATEITEM_FAIL,
      createItemError: error.message,
    })
  }
}

export const editItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.CV_PRACTICE_AREAFORM_EDITITEM_LOAD })
  try {
    await api.patch(`cv_practice_areas/${params.id}`, params)
    dispatch({ type: ActionTypes.CV_PRACTICE_AREAFORM_EDITITEM_SUCCEED })
  } catch (error) {
    dispatch({
      type: ActionTypes.CV_PRACTICE_AREAFORM_EDITITEM_FAIL,
      editItemError: error.message,
    })
  }
}

export const deleteItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.CV_PRACTICE_AREAFORM_DELETEITEM_LOAD })
  try {
    await api.delete(`cv_practice_areas/${params.id}`)
    dispatch({ type: ActionTypes.CV_PRACTICE_AREAFORM_DELETEITEM_SUCCEED })
    dispatch({ type: ActionTypes.CV_PRACTICE_AREAFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.CV_PRACTICE_AREAFORM_DELETEITEM_FAIL,
      deleteItemError: error.message,
    })
  }
}
