// Nameing: PAGE_ACTION_STATUS
// ---------------- //
// ---- {APP} ---- //
// ---------------- //
export const APP_LOGIN_SET            = 'APP_LOGIN_SET'
export const APP_LOGOUT_SET           = 'APP_LOGOUT_SET'
// ---------------- //
// ---- {LOGIN} ---- //
// ---------------- //
export const LOGIN_EDITFORM_CHANGE    = 'LOGIN_EDITFORM_CHANGE'
export const LOGIN_LOGIN_LOAD         = 'LOGIN_LOGIN_LOAD'
export const LOGIN_LOGIN_SUCCEED      = 'LOGIN_LOGIN_SUCCEED'
export const LOGIN_LOGIN_FAIL         = 'LOGIN_LOGIN_FAIL'
// ---------------- //
// ---- {ADMIN} ---- //
// ---------------- //
export const ADMIN_OPENSUBMENU_CHANGE = 'ADMIN_OPENSUBMENU_CHANGE'
export const ADMIN_TOGGLESIDER_CHANGE = 'ADMIN_TOGGLESIDER_CHANGE'// ---------------- //
// ---- {PAGE} ---- //
// ---------------- //
export const PAGELIST_FETCHITEMS_LOAD    = 'PAGELIST_FETCHITEMS_LOAD'
export const PAGELIST_FETCHITEMS_SUCCEED = 'PAGELIST_FETCHITEMS_SUCCEED'
export const PAGELIST_FETCHITEMS_FAIL    = 'PAGELIST_FETCHITEMS_FAIL'
export const PAGELIST_CHANGETABLE_CHANGE = 'PAGELIST_CHANGETABLE_CHANGE'
export const PAGELIST_SEARCHTABLE_EDIT   = 'PAGELIST_SEARCHTABLE_EDIT'
export const PAGELIST_SEARCHTABLE_SEARCH = 'PAGELIST_SEARCHTABLE_SEARCH'
// -- {PAGE} FORM -- //
export const PAGEFORM_ENTER_RESET        = 'PAGEFORM_ENTER_RESET'
export const PAGEFORM_EDITFORM_CHANGE    = 'PAGEFORM_EDITFORM_CHANGE'
export const PAGEFORM_FETCHITEM_LOAD     = 'PAGEFORM_FETCHITEM_LOAD'
export const PAGEFORM_FETCHITEM_SUCCEED  = 'PAGEFORM_FETCHITEM_SUCCEED'
export const PAGEFORM_FETCHITEM_FAIL     = 'PAGEFORM_FETCHITEM_FAIL'
export const PAGEFORM_CREATEITEM_LOAD    = 'PAGEFORM_CREATEITEM_LOAD'
export const PAGEFORM_CREATEITEM_SUCCEED = 'PAGEFORM_CREATEITEM_SUCCEED'
export const PAGEFORM_CREATEITEM_FAIL    = 'PAGEFORM_CREATEITEM_FAIL'
export const PAGEFORM_EDITITEM_LOAD      = 'PAGEFORM_EDITITEM_LOAD'
export const PAGEFORM_EDITITEM_SUCCEED   = 'PAGEFORM_EDITITEM_SUCCEED'
export const PAGEFORM_EDITITEM_FAIL      = 'PAGEFORM_EDITITEM_FAIL'
export const PAGEFORM_DELETEITEM_LOAD    = 'PAGEFORM_DELETEITEM_LOAD'
export const PAGEFORM_DELETEITEM_SUCCEED = 'PAGEFORM_DELETEITEM_SUCCEED'
export const PAGEFORM_DELETEITEM_FAIL    = 'PAGEFORM_DELETEITEM_FAIL'// ---------------- //
// ---- PRACTICEAREA ---- //
// ---------------- //
export const PRACTICEAREALIST_FETCHITEMS_LOAD    = 'PRACTICEAREALIST_FETCHITEMS_LOAD'
export const PRACTICEAREALIST_FETCHITEMS_SUCCEED = 'PRACTICEAREALIST_FETCHITEMS_SUCCEED'
export const PRACTICEAREALIST_FETCHITEMS_FAIL    = 'PRACTICEAREALIST_FETCHITEMS_FAIL'
export const PRACTICEAREALIST_CHANGETABLE_CHANGE = 'PRACTICEAREALIST_CHANGETABLE_CHANGE'
export const PRACTICEAREALIST_SEARCHTABLE_EDIT   = 'PRACTICEAREALIST_SEARCHTABLE_EDIT'
export const PRACTICEAREALIST_SEARCHTABLE_SEARCH = 'PRACTICEAREALIST_SEARCHTABLE_SEARCH'
// -- PRACTICEAREA FORM -- //
export const PRACTICEAREAFORM_ENTER_RESET        = 'PRACTICEAREAFORM_ENTER_RESET'
export const PRACTICEAREAFORM_EDITFORM_CHANGE    = 'PRACTICEAREAFORM_EDITFORM_CHANGE'
export const PRACTICEAREAFORM_FETCHITEM_LOAD     = 'PRACTICEAREAFORM_FETCHITEM_LOAD'
export const PRACTICEAREAFORM_FETCHITEM_SUCCEED  = 'PRACTICEAREAFORM_FETCHITEM_SUCCEED'
export const PRACTICEAREAFORM_FETCHITEM_FAIL     = 'PRACTICEAREAFORM_FETCHITEM_FAIL'
export const PRACTICEAREAFORM_CREATEITEM_LOAD    = 'PRACTICEAREAFORM_CREATEITEM_LOAD'
export const PRACTICEAREAFORM_CREATEITEM_SUCCEED = 'PRACTICEAREAFORM_CREATEITEM_SUCCEED'
export const PRACTICEAREAFORM_CREATEITEM_FAIL    = 'PRACTICEAREAFORM_CREATEITEM_FAIL'
export const PRACTICEAREAFORM_EDITITEM_LOAD      = 'PRACTICEAREAFORM_EDITITEM_LOAD'
export const PRACTICEAREAFORM_EDITITEM_SUCCEED   = 'PRACTICEAREAFORM_EDITITEM_SUCCEED'
export const PRACTICEAREAFORM_EDITITEM_FAIL      = 'PRACTICEAREAFORM_EDITITEM_FAIL'
export const PRACTICEAREAFORM_DELETEITEM_LOAD    = 'PRACTICEAREAFORM_DELETEITEM_LOAD'
export const PRACTICEAREAFORM_DELETEITEM_SUCCEED = 'PRACTICEAREAFORM_DELETEITEM_SUCCEED'
export const PRACTICEAREAFORM_DELETEITEM_FAIL    = 'PRACTICEAREAFORM_DELETEITEM_FAIL'
// ---------------- //
// ---- ADMINISTRATOR ---- //
// ---------------- //
export const ADMINISTRATORLIST_FETCHITEMS_LOAD    = 'ADMINISTRATORLIST_FETCHITEMS_LOAD'
export const ADMINISTRATORLIST_FETCHITEMS_SUCCEED = 'ADMINISTRATORLIST_FETCHITEMS_SUCCEED'
export const ADMINISTRATORLIST_FETCHITEMS_FAIL    = 'ADMINISTRATORLIST_FETCHITEMS_FAIL'
export const ADMINISTRATORLIST_CHANGETABLE_CHANGE = 'ADMINISTRATORLIST_CHANGETABLE_CHANGE'
export const ADMINISTRATORLIST_SEARCHTABLE_EDIT   = 'ADMINISTRATORLIST_SEARCHTABLE_EDIT'
export const ADMINISTRATORLIST_SEARCHTABLE_SEARCH = 'ADMINISTRATORLIST_SEARCHTABLE_SEARCH'
// -- ADMINISTRATOR FORM -- //
export const ADMINISTRATORFORM_ENTER_RESET        = 'ADMINISTRATORFORM_ENTER_RESET'
export const ADMINISTRATORFORM_EDITFORM_CHANGE    = 'ADMINISTRATORFORM_EDITFORM_CHANGE'
export const ADMINISTRATORFORM_FETCHITEM_LOAD     = 'ADMINISTRATORFORM_FETCHITEM_LOAD'
export const ADMINISTRATORFORM_FETCHITEM_SUCCEED  = 'ADMINISTRATORFORM_FETCHITEM_SUCCEED'
export const ADMINISTRATORFORM_FETCHITEM_FAIL     = 'ADMINISTRATORFORM_FETCHITEM_FAIL'
export const ADMINISTRATORFORM_CREATEITEM_LOAD    = 'ADMINISTRATORFORM_CREATEITEM_LOAD'
export const ADMINISTRATORFORM_CREATEITEM_SUCCEED = 'ADMINISTRATORFORM_CREATEITEM_SUCCEED'
export const ADMINISTRATORFORM_CREATEITEM_FAIL    = 'ADMINISTRATORFORM_CREATEITEM_FAIL'
export const ADMINISTRATORFORM_EDITITEM_LOAD      = 'ADMINISTRATORFORM_EDITITEM_LOAD'
export const ADMINISTRATORFORM_EDITITEM_SUCCEED   = 'ADMINISTRATORFORM_EDITITEM_SUCCEED'
export const ADMINISTRATORFORM_EDITITEM_FAIL      = 'ADMINISTRATORFORM_EDITITEM_FAIL'
export const ADMINISTRATORFORM_DELETEITEM_LOAD    = 'ADMINISTRATORFORM_DELETEITEM_LOAD'
export const ADMINISTRATORFORM_DELETEITEM_SUCCEED = 'ADMINISTRATORFORM_DELETEITEM_SUCCEED'
export const ADMINISTRATORFORM_DELETEITEM_FAIL    = 'ADMINISTRATORFORM_DELETEITEM_FAIL'
// ---------------- //
// ---- PAGESTEMPLATE ---- //
// ---------------- //
export const PAGESTEMPLATELIST_FETCHITEMS_LOAD    = 'PAGESTEMPLATELIST_FETCHITEMS_LOAD'
export const PAGESTEMPLATELIST_FETCHITEMS_SUCCEED = 'PAGESTEMPLATELIST_FETCHITEMS_SUCCEED'
export const PAGESTEMPLATELIST_FETCHITEMS_FAIL    = 'PAGESTEMPLATELIST_FETCHITEMS_FAIL'
export const PAGESTEMPLATELIST_CHANGETABLE_CHANGE = 'PAGESTEMPLATELIST_CHANGETABLE_CHANGE'
export const PAGESTEMPLATELIST_SEARCHTABLE_EDIT   = 'PAGESTEMPLATELIST_SEARCHTABLE_EDIT'
export const PAGESTEMPLATELIST_SEARCHTABLE_SEARCH = 'PAGESTEMPLATELIST_SEARCHTABLE_SEARCH'
// -- PAGESTEMPLATE FORM -- //
export const PAGESTEMPLATEFORM_ENTER_PREPARE_SUCCEED = 'PAGESTEMPLATEFORM_ENTER_PREPARE_SUCCEED'
export const PAGETEMPLATEFORM_ENTER_RESET        = 'PAGETEMPLATEFORM_ENTER_RESET'
export const PAGETEMPLATEFORM_EDITFORM_CHANGE    = 'PAGETEMPLATEFORM_EDITFORM_CHANGE'
export const PAGETEMPLATEFORM_FETCHITEM_LOAD     = 'PAGETEMPLATEFORM_FETCHITEM_LOAD'
export const PAGETEMPLATEFORM_FETCHITEM_SUCCEED  = 'PAGETEMPLATEFORM_FETCHITEM_SUCCEED'
export const PAGETEMPLATEFORM_FETCHITEM_FAIL     = 'PAGETEMPLATEFORM_FETCHITEM_FAIL'
export const PAGETEMPLATEFORM_CREATEITEM_LOAD    = 'PAGETEMPLATEFORM_CREATEITEM_LOAD'
export const PAGETEMPLATEFORM_CREATEITEM_SUCCEED = 'PAGETEMPLATEFORM_CREATEITEM_SUCCEED'
export const PAGETEMPLATEFORM_CREATEITEM_FAIL    = 'PAGETEMPLATEFORM_CREATEITEM_FAIL'
export const PAGETEMPLATEFORM_EDITITEM_LOAD      = 'PAGETEMPLATEFORM_EDITITEM_LOAD'
export const PAGETEMPLATEFORM_EDITITEM_SUCCEED   = 'PAGETEMPLATEFORM_EDITITEM_SUCCEED'
export const PAGETEMPLATEFORM_EDITITEM_FAIL      = 'PAGETEMPLATEFORM_EDITITEM_FAIL'
export const PAGETEMPLATEFORM_DELETEITEM_LOAD    = 'PAGETEMPLATEFORM_DELETEITEM_LOAD'
export const PAGETEMPLATEFORM_DELETEITEM_SUCCEED = 'PAGETEMPLATEFORM_DELETEITEM_SUCCEED'
export const PAGETEMPLATEFORM_DELETEITEM_FAIL    = 'PAGETEMPLATEFORM_DELETEITEM_FAIL'// ---------------- //
// ---- MEMBER ---- //
// ---------------- //
export const MEMBERLIST_FETCHITEMS_LOAD    = 'MEMBERLIST_FETCHITEMS_LOAD'
export const MEMBERLIST_FETCHITEMS_SUCCEED = 'MEMBERLIST_FETCHITEMS_SUCCEED'
export const MEMBERLIST_FETCHITEMS_FAIL    = 'MEMBERLIST_FETCHITEMS_FAIL'
export const MEMBERLIST_CHANGETABLE_CHANGE = 'MEMBERLIST_CHANGETABLE_CHANGE'
export const MEMBERLIST_SEARCHTABLE_EDIT   = 'MEMBERLIST_SEARCHTABLE_EDIT'
export const MEMBERLIST_SEARCHTABLE_SEARCH = 'MEMBERLIST_SEARCHTABLE_SEARCH'
// -- MEMBER FORM -- //
export const MEMBERFORM_ENTER_RESET        = 'MEMBERFORM_ENTER_RESET'
export const MEMBERFORM_EDITFORM_CHANGE    = 'MEMBERFORM_EDITFORM_CHANGE'
export const MEMBERFORM_FETCHITEM_LOAD     = 'MEMBERFORM_FETCHITEM_LOAD'
export const MEMBERFORM_FETCHITEM_SUCCEED  = 'MEMBERFORM_FETCHITEM_SUCCEED'
export const MEMBERFORM_FETCHITEM_FAIL     = 'MEMBERFORM_FETCHITEM_FAIL'
export const MEMBERFORM_CREATEITEM_LOAD    = 'MEMBERFORM_CREATEITEM_LOAD'
export const MEMBERFORM_CREATEITEM_SUCCEED = 'MEMBERFORM_CREATEITEM_SUCCEED'
export const MEMBERFORM_CREATEITEM_FAIL    = 'MEMBERFORM_CREATEITEM_FAIL'
export const MEMBERFORM_EDITITEM_LOAD      = 'MEMBERFORM_EDITITEM_LOAD'
export const MEMBERFORM_EDITITEM_SUCCEED   = 'MEMBERFORM_EDITITEM_SUCCEED'
export const MEMBERFORM_EDITITEM_FAIL      = 'MEMBERFORM_EDITITEM_FAIL'
export const MEMBERFORM_DELETEITEM_LOAD    = 'MEMBERFORM_DELETEITEM_LOAD'
export const MEMBERFORM_DELETEITEM_SUCCEED = 'MEMBERFORM_DELETEITEM_SUCCEED'
export const MEMBERFORM_DELETEITEM_FAIL    = 'MEMBERFORM_DELETEITEM_FAIL'// ---------------- //
// ---- CV_APPOINTMENTS_AND_PUBLIC_OFFICE ---- //
// ---------------- //
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_FETCHITEMS_LOAD    = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_FETCHITEMS_LOAD'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_FETCHITEMS_SUCCEED = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_FETCHITEMS_SUCCEED'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_FETCHITEMS_FAIL    = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_FETCHITEMS_FAIL'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_CHANGETABLE_CHANGE = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_CHANGETABLE_CHANGE'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_SEARCHTABLE_EDIT   = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_SEARCHTABLE_EDIT'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_SEARCHTABLE_SEARCH = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICELIST_SEARCHTABLE_SEARCH'
// -- CV_APPOINTMENTS_AND_PUBLIC_OFFICE FORM -- //
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_ENTER_RESET        = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_ENTER_RESET'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_EDITFORM_CHANGE    = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_EDITFORM_CHANGE'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_FETCHITEM_LOAD     = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_FETCHITEM_LOAD'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_FETCHITEM_SUCCEED  = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_FETCHITEM_SUCCEED'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_FETCHITEM_FAIL     = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_FETCHITEM_FAIL'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_CREATEITEM_LOAD    = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_CREATEITEM_LOAD'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_CREATEITEM_SUCCEED = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_CREATEITEM_SUCCEED'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_CREATEITEM_FAIL    = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_CREATEITEM_FAIL'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_EDITITEM_LOAD      = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_EDITITEM_LOAD'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_EDITITEM_SUCCEED   = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_EDITITEM_SUCCEED'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_EDITITEM_FAIL      = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_EDITITEM_FAIL'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_DELETEITEM_LOAD    = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_DELETEITEM_LOAD'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_DELETEITEM_SUCCEED = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_DELETEITEM_SUCCEED'
export const CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_DELETEITEM_FAIL    = 'CV_APPOINTMENTS_AND_PUBLIC_OFFICEFORM_DELETEITEM_FAIL'// ---------------- //
// ---- CV_EDUCATION ---- //
// ---------------- //
export const CV_EDUCATIONLIST_FETCHITEMS_LOAD    = 'CV_EDUCATIONLIST_FETCHITEMS_LOAD'
export const CV_EDUCATIONLIST_FETCHITEMS_SUCCEED = 'CV_EDUCATIONLIST_FETCHITEMS_SUCCEED'
export const CV_EDUCATIONLIST_FETCHITEMS_FAIL    = 'CV_EDUCATIONLIST_FETCHITEMS_FAIL'
export const CV_EDUCATIONLIST_CHANGETABLE_CHANGE = 'CV_EDUCATIONLIST_CHANGETABLE_CHANGE'
export const CV_EDUCATIONLIST_SEARCHTABLE_EDIT   = 'CV_EDUCATIONLIST_SEARCHTABLE_EDIT'
export const CV_EDUCATIONLIST_SEARCHTABLE_SEARCH = 'CV_EDUCATIONLIST_SEARCHTABLE_SEARCH'
// -- CV_EDUCATION FORM -- //
export const CV_EDUCATIONFORM_ENTER_RESET        = 'CV_EDUCATIONFORM_ENTER_RESET'
export const CV_EDUCATIONFORM_EDITFORM_CHANGE    = 'CV_EDUCATIONFORM_EDITFORM_CHANGE'
export const CV_EDUCATIONFORM_FETCHITEM_LOAD     = 'CV_EDUCATIONFORM_FETCHITEM_LOAD'
export const CV_EDUCATIONFORM_FETCHITEM_SUCCEED  = 'CV_EDUCATIONFORM_FETCHITEM_SUCCEED'
export const CV_EDUCATIONFORM_FETCHITEM_FAIL     = 'CV_EDUCATIONFORM_FETCHITEM_FAIL'
export const CV_EDUCATIONFORM_CREATEITEM_LOAD    = 'CV_EDUCATIONFORM_CREATEITEM_LOAD'
export const CV_EDUCATIONFORM_CREATEITEM_SUCCEED = 'CV_EDUCATIONFORM_CREATEITEM_SUCCEED'
export const CV_EDUCATIONFORM_CREATEITEM_FAIL    = 'CV_EDUCATIONFORM_CREATEITEM_FAIL'
export const CV_EDUCATIONFORM_EDITITEM_LOAD      = 'CV_EDUCATIONFORM_EDITITEM_LOAD'
export const CV_EDUCATIONFORM_EDITITEM_SUCCEED   = 'CV_EDUCATIONFORM_EDITITEM_SUCCEED'
export const CV_EDUCATIONFORM_EDITITEM_FAIL      = 'CV_EDUCATIONFORM_EDITITEM_FAIL'
export const CV_EDUCATIONFORM_DELETEITEM_LOAD    = 'CV_EDUCATIONFORM_DELETEITEM_LOAD'
export const CV_EDUCATIONFORM_DELETEITEM_SUCCEED = 'CV_EDUCATIONFORM_DELETEITEM_SUCCEED'
export const CV_EDUCATIONFORM_DELETEITEM_FAIL    = 'CV_EDUCATIONFORM_DELETEITEM_FAIL'// ---------------- //
// ---- CV_PUBLICATIONS_AND_ARTICLE ---- //
// ---------------- //
export const CV_PUBLICATIONS_AND_ARTICLELIST_FETCHITEMS_LOAD    = 'CV_PUBLICATIONS_AND_ARTICLELIST_FETCHITEMS_LOAD'
export const CV_PUBLICATIONS_AND_ARTICLELIST_FETCHITEMS_SUCCEED = 'CV_PUBLICATIONS_AND_ARTICLELIST_FETCHITEMS_SUCCEED'
export const CV_PUBLICATIONS_AND_ARTICLELIST_FETCHITEMS_FAIL    = 'CV_PUBLICATIONS_AND_ARTICLELIST_FETCHITEMS_FAIL'
export const CV_PUBLICATIONS_AND_ARTICLELIST_CHANGETABLE_CHANGE = 'CV_PUBLICATIONS_AND_ARTICLELIST_CHANGETABLE_CHANGE'
export const CV_PUBLICATIONS_AND_ARTICLELIST_SEARCHTABLE_EDIT   = 'CV_PUBLICATIONS_AND_ARTICLELIST_SEARCHTABLE_EDIT'
export const CV_PUBLICATIONS_AND_ARTICLELIST_SEARCHTABLE_SEARCH = 'CV_PUBLICATIONS_AND_ARTICLELIST_SEARCHTABLE_SEARCH'
// -- CV_PUBLICATIONS_AND_ARTICLE FORM -- //
export const CV_PUBLICATIONS_AND_ARTICLEFORM_ENTER_RESET        = 'CV_PUBLICATIONS_AND_ARTICLEFORM_ENTER_RESET'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_EDITFORM_CHANGE    = 'CV_PUBLICATIONS_AND_ARTICLEFORM_EDITFORM_CHANGE'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_FETCHITEM_LOAD     = 'CV_PUBLICATIONS_AND_ARTICLEFORM_FETCHITEM_LOAD'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_FETCHITEM_SUCCEED  = 'CV_PUBLICATIONS_AND_ARTICLEFORM_FETCHITEM_SUCCEED'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_FETCHITEM_FAIL     = 'CV_PUBLICATIONS_AND_ARTICLEFORM_FETCHITEM_FAIL'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_CREATEITEM_LOAD    = 'CV_PUBLICATIONS_AND_ARTICLEFORM_CREATEITEM_LOAD'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_CREATEITEM_SUCCEED = 'CV_PUBLICATIONS_AND_ARTICLEFORM_CREATEITEM_SUCCEED'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_CREATEITEM_FAIL    = 'CV_PUBLICATIONS_AND_ARTICLEFORM_CREATEITEM_FAIL'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_EDITITEM_LOAD      = 'CV_PUBLICATIONS_AND_ARTICLEFORM_EDITITEM_LOAD'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_EDITITEM_SUCCEED   = 'CV_PUBLICATIONS_AND_ARTICLEFORM_EDITITEM_SUCCEED'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_EDITITEM_FAIL      = 'CV_PUBLICATIONS_AND_ARTICLEFORM_EDITITEM_FAIL'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_DELETEITEM_LOAD    = 'CV_PUBLICATIONS_AND_ARTICLEFORM_DELETEITEM_LOAD'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_DELETEITEM_SUCCEED = 'CV_PUBLICATIONS_AND_ARTICLEFORM_DELETEITEM_SUCCEED'
export const CV_PUBLICATIONS_AND_ARTICLEFORM_DELETEITEM_FAIL    = 'CV_PUBLICATIONS_AND_ARTICLEFORM_DELETEITEM_FAIL'// ---------------- //
// ---- CV_PRACTICE_AREA ---- //
// ---------------- //
export const CV_PRACTICE_AREALIST_FETCHITEMS_LOAD    = 'CV_PRACTICE_AREALIST_FETCHITEMS_LOAD'
export const CV_PRACTICE_AREALIST_FETCHITEMS_SUCCEED = 'CV_PRACTICE_AREALIST_FETCHITEMS_SUCCEED'
export const CV_PRACTICE_AREALIST_FETCHITEMS_FAIL    = 'CV_PRACTICE_AREALIST_FETCHITEMS_FAIL'
export const CV_PRACTICE_AREALIST_CHANGETABLE_CHANGE = 'CV_PRACTICE_AREALIST_CHANGETABLE_CHANGE'
export const CV_PRACTICE_AREALIST_SEARCHTABLE_EDIT   = 'CV_PRACTICE_AREALIST_SEARCHTABLE_EDIT'
export const CV_PRACTICE_AREALIST_SEARCHTABLE_SEARCH = 'CV_PRACTICE_AREALIST_SEARCHTABLE_SEARCH'
// -- CV_PRACTICE_AREA FORM -- //
export const CV_PRACTICE_AREAFORM_ENTER_RESET        = 'CV_PRACTICE_AREAFORM_ENTER_RESET'
export const CV_PRACTICE_AREAFORM_EDITFORM_CHANGE    = 'CV_PRACTICE_AREAFORM_EDITFORM_CHANGE'
export const CV_PRACTICE_AREAFORM_FETCHITEM_LOAD     = 'CV_PRACTICE_AREAFORM_FETCHITEM_LOAD'
export const CV_PRACTICE_AREAFORM_FETCHITEM_SUCCEED  = 'CV_PRACTICE_AREAFORM_FETCHITEM_SUCCEED'
export const CV_PRACTICE_AREAFORM_FETCHITEM_FAIL     = 'CV_PRACTICE_AREAFORM_FETCHITEM_FAIL'
export const CV_PRACTICE_AREAFORM_CREATEITEM_LOAD    = 'CV_PRACTICE_AREAFORM_CREATEITEM_LOAD'
export const CV_PRACTICE_AREAFORM_CREATEITEM_SUCCEED = 'CV_PRACTICE_AREAFORM_CREATEITEM_SUCCEED'
export const CV_PRACTICE_AREAFORM_CREATEITEM_FAIL    = 'CV_PRACTICE_AREAFORM_CREATEITEM_FAIL'
export const CV_PRACTICE_AREAFORM_EDITITEM_LOAD      = 'CV_PRACTICE_AREAFORM_EDITITEM_LOAD'
export const CV_PRACTICE_AREAFORM_EDITITEM_SUCCEED   = 'CV_PRACTICE_AREAFORM_EDITITEM_SUCCEED'
export const CV_PRACTICE_AREAFORM_EDITITEM_FAIL      = 'CV_PRACTICE_AREAFORM_EDITITEM_FAIL'
export const CV_PRACTICE_AREAFORM_DELETEITEM_LOAD    = 'CV_PRACTICE_AREAFORM_DELETEITEM_LOAD'
export const CV_PRACTICE_AREAFORM_DELETEITEM_SUCCEED = 'CV_PRACTICE_AREAFORM_DELETEITEM_SUCCEED'
export const CV_PRACTICE_AREAFORM_DELETEITEM_FAIL    = 'CV_PRACTICE_AREAFORM_DELETEITEM_FAIL'// ---------------- //
// ---- CV_SELECTED_CASE ---- //
// ---------------- //
export const CV_SELECTED_CASELIST_FETCHITEMS_LOAD    = 'CV_SELECTED_CASELIST_FETCHITEMS_LOAD'
export const CV_SELECTED_CASELIST_FETCHITEMS_SUCCEED = 'CV_SELECTED_CASELIST_FETCHITEMS_SUCCEED'
export const CV_SELECTED_CASELIST_FETCHITEMS_FAIL    = 'CV_SELECTED_CASELIST_FETCHITEMS_FAIL'
export const CV_SELECTED_CASELIST_CHANGETABLE_CHANGE = 'CV_SELECTED_CASELIST_CHANGETABLE_CHANGE'
export const CV_SELECTED_CASELIST_SEARCHTABLE_EDIT   = 'CV_SELECTED_CASELIST_SEARCHTABLE_EDIT'
export const CV_SELECTED_CASELIST_SEARCHTABLE_SEARCH = 'CV_SELECTED_CASELIST_SEARCHTABLE_SEARCH'
// -- CV_SELECTED_CASE FORM -- //
export const CV_SELECTED_CASEFORM_ENTER_RESET        = 'CV_SELECTED_CASEFORM_ENTER_RESET'
export const CV_SELECTED_CASEFORM_EDITFORM_CHANGE    = 'CV_SELECTED_CASEFORM_EDITFORM_CHANGE'
export const CV_SELECTED_CASEFORM_FETCHITEM_LOAD     = 'CV_SELECTED_CASEFORM_FETCHITEM_LOAD'
export const CV_SELECTED_CASEFORM_FETCHITEM_SUCCEED  = 'CV_SELECTED_CASEFORM_FETCHITEM_SUCCEED'
export const CV_SELECTED_CASEFORM_FETCHITEM_FAIL     = 'CV_SELECTED_CASEFORM_FETCHITEM_FAIL'
export const CV_SELECTED_CASEFORM_CREATEITEM_LOAD    = 'CV_SELECTED_CASEFORM_CREATEITEM_LOAD'
export const CV_SELECTED_CASEFORM_CREATEITEM_SUCCEED = 'CV_SELECTED_CASEFORM_CREATEITEM_SUCCEED'
export const CV_SELECTED_CASEFORM_CREATEITEM_FAIL    = 'CV_SELECTED_CASEFORM_CREATEITEM_FAIL'
export const CV_SELECTED_CASEFORM_EDITITEM_LOAD      = 'CV_SELECTED_CASEFORM_EDITITEM_LOAD'
export const CV_SELECTED_CASEFORM_EDITITEM_SUCCEED   = 'CV_SELECTED_CASEFORM_EDITITEM_SUCCEED'
export const CV_SELECTED_CASEFORM_EDITITEM_FAIL      = 'CV_SELECTED_CASEFORM_EDITITEM_FAIL'
export const CV_SELECTED_CASEFORM_DELETEITEM_LOAD    = 'CV_SELECTED_CASEFORM_DELETEITEM_LOAD'
export const CV_SELECTED_CASEFORM_DELETEITEM_SUCCEED = 'CV_SELECTED_CASEFORM_DELETEITEM_SUCCEED'
export const CV_SELECTED_CASEFORM_DELETEITEM_FAIL    = 'CV_SELECTED_CASEFORM_DELETEITEM_FAIL'// ---------------- //
// ---- CV_SCHOLARSHIP_AND_PRIZE ---- //
// ---------------- //
export const CV_SCHOLARSHIP_AND_PRIZELIST_FETCHITEMS_LOAD    = 'CV_SCHOLARSHIP_AND_PRIZELIST_FETCHITEMS_LOAD'
export const CV_SCHOLARSHIP_AND_PRIZELIST_FETCHITEMS_SUCCEED = 'CV_SCHOLARSHIP_AND_PRIZELIST_FETCHITEMS_SUCCEED'
export const CV_SCHOLARSHIP_AND_PRIZELIST_FETCHITEMS_FAIL    = 'CV_SCHOLARSHIP_AND_PRIZELIST_FETCHITEMS_FAIL'
export const CV_SCHOLARSHIP_AND_PRIZELIST_CHANGETABLE_CHANGE = 'CV_SCHOLARSHIP_AND_PRIZELIST_CHANGETABLE_CHANGE'
export const CV_SCHOLARSHIP_AND_PRIZELIST_SEARCHTABLE_EDIT   = 'CV_SCHOLARSHIP_AND_PRIZELIST_SEARCHTABLE_EDIT'
export const CV_SCHOLARSHIP_AND_PRIZELIST_SEARCHTABLE_SEARCH = 'CV_SCHOLARSHIP_AND_PRIZELIST_SEARCHTABLE_SEARCH'
// -- CV_SCHOLARSHIP_AND_PRIZE FORM -- //
export const CV_SCHOLARSHIP_AND_PRIZEFORM_ENTER_RESET        = 'CV_SCHOLARSHIP_AND_PRIZEFORM_ENTER_RESET'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_EDITFORM_CHANGE    = 'CV_SCHOLARSHIP_AND_PRIZEFORM_EDITFORM_CHANGE'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_FETCHITEM_LOAD     = 'CV_SCHOLARSHIP_AND_PRIZEFORM_FETCHITEM_LOAD'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_FETCHITEM_SUCCEED  = 'CV_SCHOLARSHIP_AND_PRIZEFORM_FETCHITEM_SUCCEED'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_FETCHITEM_FAIL     = 'CV_SCHOLARSHIP_AND_PRIZEFORM_FETCHITEM_FAIL'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_CREATEITEM_LOAD    = 'CV_SCHOLARSHIP_AND_PRIZEFORM_CREATEITEM_LOAD'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_CREATEITEM_SUCCEED = 'CV_SCHOLARSHIP_AND_PRIZEFORM_CREATEITEM_SUCCEED'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_CREATEITEM_FAIL    = 'CV_SCHOLARSHIP_AND_PRIZEFORM_CREATEITEM_FAIL'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_EDITITEM_LOAD      = 'CV_SCHOLARSHIP_AND_PRIZEFORM_EDITITEM_LOAD'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_EDITITEM_SUCCEED   = 'CV_SCHOLARSHIP_AND_PRIZEFORM_EDITITEM_SUCCEED'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_EDITITEM_FAIL      = 'CV_SCHOLARSHIP_AND_PRIZEFORM_EDITITEM_FAIL'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_DELETEITEM_LOAD    = 'CV_SCHOLARSHIP_AND_PRIZEFORM_DELETEITEM_LOAD'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_DELETEITEM_SUCCEED = 'CV_SCHOLARSHIP_AND_PRIZEFORM_DELETEITEM_SUCCEED'
export const CV_SCHOLARSHIP_AND_PRIZEFORM_DELETEITEM_FAIL    = 'CV_SCHOLARSHIP_AND_PRIZEFORM_DELETEITEM_FAIL'// ---------------- //
// ---- CV_SELECTED_CASES_CASE ---- //
// ---------------- //
export const CV_SELECTED_CASES_CASELIST_FETCHITEMS_LOAD    = 'CV_SELECTED_CASES_CASELIST_FETCHITEMS_LOAD'
export const CV_SELECTED_CASES_CASELIST_FETCHITEMS_SUCCEED = 'CV_SELECTED_CASES_CASELIST_FETCHITEMS_SUCCEED'
export const CV_SELECTED_CASES_CASELIST_FETCHITEMS_FAIL    = 'CV_SELECTED_CASES_CASELIST_FETCHITEMS_FAIL'
export const CV_SELECTED_CASES_CASELIST_CHANGETABLE_CHANGE = 'CV_SELECTED_CASES_CASELIST_CHANGETABLE_CHANGE'
export const CV_SELECTED_CASES_CASELIST_SEARCHTABLE_EDIT   = 'CV_SELECTED_CASES_CASELIST_SEARCHTABLE_EDIT'
export const CV_SELECTED_CASES_CASELIST_SEARCHTABLE_SEARCH = 'CV_SELECTED_CASES_CASELIST_SEARCHTABLE_SEARCH'
// -- CV_SELECTED_CASES_CASE FORM -- //
export const CV_SELECTED_CASES_CASEFORM_ENTER_RESET        = 'CV_SELECTED_CASES_CASEFORM_ENTER_RESET'
export const CV_SELECTED_CASES_CASEFORM_EDITFORM_CHANGE    = 'CV_SELECTED_CASES_CASEFORM_EDITFORM_CHANGE'
export const CV_SELECTED_CASES_CASEFORM_FETCHITEM_LOAD     = 'CV_SELECTED_CASES_CASEFORM_FETCHITEM_LOAD'
export const CV_SELECTED_CASES_CASEFORM_FETCHITEM_SUCCEED  = 'CV_SELECTED_CASES_CASEFORM_FETCHITEM_SUCCEED'
export const CV_SELECTED_CASES_CASEFORM_FETCHITEM_FAIL     = 'CV_SELECTED_CASES_CASEFORM_FETCHITEM_FAIL'
export const CV_SELECTED_CASES_CASEFORM_CREATEITEM_LOAD    = 'CV_SELECTED_CASES_CASEFORM_CREATEITEM_LOAD'
export const CV_SELECTED_CASES_CASEFORM_CREATEITEM_SUCCEED = 'CV_SELECTED_CASES_CASEFORM_CREATEITEM_SUCCEED'
export const CV_SELECTED_CASES_CASEFORM_CREATEITEM_FAIL    = 'CV_SELECTED_CASES_CASEFORM_CREATEITEM_FAIL'
export const CV_SELECTED_CASES_CASEFORM_EDITITEM_LOAD      = 'CV_SELECTED_CASES_CASEFORM_EDITITEM_LOAD'
export const CV_SELECTED_CASES_CASEFORM_EDITITEM_SUCCEED   = 'CV_SELECTED_CASES_CASEFORM_EDITITEM_SUCCEED'
export const CV_SELECTED_CASES_CASEFORM_EDITITEM_FAIL      = 'CV_SELECTED_CASES_CASEFORM_EDITITEM_FAIL'
export const CV_SELECTED_CASES_CASEFORM_DELETEITEM_LOAD    = 'CV_SELECTED_CASES_CASEFORM_DELETEITEM_LOAD'
export const CV_SELECTED_CASES_CASEFORM_DELETEITEM_SUCCEED = 'CV_SELECTED_CASES_CASEFORM_DELETEITEM_SUCCEED'
export const CV_SELECTED_CASES_CASEFORM_DELETEITEM_FAIL    = 'CV_SELECTED_CASES_CASEFORM_DELETEITEM_FAIL'// ---------------- //// ---------------- //
// ---- NEW ---- //
// ---------------- //
export const NEWLIST_FETCHITEMS_LOAD    = 'NEWLIST_FETCHITEMS_LOAD'
export const NEWLIST_FETCHITEMS_SUCCEED = 'NEWLIST_FETCHITEMS_SUCCEED'
export const NEWLIST_FETCHITEMS_FAIL    = 'NEWLIST_FETCHITEMS_FAIL'
export const NEWLIST_CHANGETABLE_CHANGE = 'NEWLIST_CHANGETABLE_CHANGE'
export const NEWLIST_SEARCHTABLE_EDIT   = 'NEWLIST_SEARCHTABLE_EDIT'
export const NEWLIST_SEARCHTABLE_SEARCH = 'NEWLIST_SEARCHTABLE_SEARCH'
// -- NEW FORM -- //
export const NEWFORM_ENTER_RESET        = 'NEWFORM_ENTER_RESET'
export const NEWFORM_EDITFORM_CHANGE    = 'NEWFORM_EDITFORM_CHANGE'
export const NEWFORM_FETCHITEM_LOAD     = 'NEWFORM_FETCHITEM_LOAD'
export const NEWFORM_FETCHITEM_SUCCEED  = 'NEWFORM_FETCHITEM_SUCCEED'
export const NEWFORM_FETCHITEM_FAIL     = 'NEWFORM_FETCHITEM_FAIL'
export const NEWFORM_CREATEITEM_LOAD    = 'NEWFORM_CREATEITEM_LOAD'
export const NEWFORM_CREATEITEM_SUCCEED = 'NEWFORM_CREATEITEM_SUCCEED'
export const NEWFORM_CREATEITEM_FAIL    = 'NEWFORM_CREATEITEM_FAIL'
export const NEWFORM_EDITITEM_LOAD      = 'NEWFORM_EDITITEM_LOAD'
export const NEWFORM_EDITITEM_SUCCEED   = 'NEWFORM_EDITITEM_SUCCEED'
export const NEWFORM_EDITITEM_FAIL      = 'NEWFORM_EDITITEM_FAIL'
export const NEWFORM_DELETEITEM_LOAD    = 'NEWFORM_DELETEITEM_LOAD'
export const NEWFORM_DELETEITEM_SUCCEED = 'NEWFORM_DELETEITEM_SUCCEED'
export const NEWFORM_DELETEITEM_FAIL    = 'NEWFORM_DELETEITEM_FAIL'// ---------------- //
// ---- EVENT ---- //
// ---------------- //
export const EVENTLIST_FETCHITEMS_LOAD    = 'EVENTLIST_FETCHITEMS_LOAD'
export const EVENTLIST_FETCHITEMS_SUCCEED = 'EVENTLIST_FETCHITEMS_SUCCEED'
export const EVENTLIST_FETCHITEMS_FAIL    = 'EVENTLIST_FETCHITEMS_FAIL'
export const EVENTLIST_CHANGETABLE_CHANGE = 'EVENTLIST_CHANGETABLE_CHANGE'
export const EVENTLIST_SEARCHTABLE_EDIT   = 'EVENTLIST_SEARCHTABLE_EDIT'
export const EVENTLIST_SEARCHTABLE_SEARCH = 'EVENTLIST_SEARCHTABLE_SEARCH'
// -- EVENT FORM -- //
export const EVENTFORM_ENTER_RESET        = 'EVENTFORM_ENTER_RESET'
export const EVENTFORM_EDITFORM_CHANGE    = 'EVENTFORM_EDITFORM_CHANGE'
export const EVENTFORM_FETCHITEM_LOAD     = 'EVENTFORM_FETCHITEM_LOAD'
export const EVENTFORM_FETCHITEM_SUCCEED  = 'EVENTFORM_FETCHITEM_SUCCEED'
export const EVENTFORM_FETCHITEM_FAIL     = 'EVENTFORM_FETCHITEM_FAIL'
export const EVENTFORM_CREATEITEM_LOAD    = 'EVENTFORM_CREATEITEM_LOAD'
export const EVENTFORM_CREATEITEM_SUCCEED = 'EVENTFORM_CREATEITEM_SUCCEED'
export const EVENTFORM_CREATEITEM_FAIL    = 'EVENTFORM_CREATEITEM_FAIL'
export const EVENTFORM_EDITITEM_LOAD      = 'EVENTFORM_EDITITEM_LOAD'
export const EVENTFORM_EDITITEM_SUCCEED   = 'EVENTFORM_EDITITEM_SUCCEED'
export const EVENTFORM_EDITITEM_FAIL      = 'EVENTFORM_EDITITEM_FAIL'
export const EVENTFORM_DELETEITEM_LOAD    = 'EVENTFORM_DELETEITEM_LOAD'
export const EVENTFORM_DELETEITEM_SUCCEED = 'EVENTFORM_DELETEITEM_SUCCEED'
export const EVENTFORM_DELETEITEM_FAIL    = 'EVENTFORM_DELETEITEM_FAIL'// ---------------- //
// ---- ASSET ---- //
// ---------------- //
export const ASSETLIST_FETCHITEMS_LOAD    = 'ASSETLIST_FETCHITEMS_LOAD'
export const ASSETLIST_FETCHITEMS_SUCCEED = 'ASSETLIST_FETCHITEMS_SUCCEED'
export const ASSETLIST_FETCHITEMS_FAIL    = 'ASSETLIST_FETCHITEMS_FAIL'
export const ASSETLIST_CHANGETABLE_CHANGE = 'ASSETLIST_CHANGETABLE_CHANGE'
export const ASSETLIST_SEARCHTABLE_EDIT   = 'ASSETLIST_SEARCHTABLE_EDIT'
export const ASSETLIST_SEARCHTABLE_SEARCH = 'ASSETLIST_SEARCHTABLE_SEARCH'
// -- ASSET FORM -- //
export const ASSETFORM_ENTER_RESET        = 'ASSETFORM_ENTER_RESET'
export const ASSETFORM_EDITFORM_CHANGE    = 'ASSETFORM_EDITFORM_CHANGE'
export const ASSETFORM_FETCHITEM_LOAD     = 'ASSETFORM_FETCHITEM_LOAD'
export const ASSETFORM_FETCHITEM_SUCCEED  = 'ASSETFORM_FETCHITEM_SUCCEED'
export const ASSETFORM_FETCHITEM_FAIL     = 'ASSETFORM_FETCHITEM_FAIL'
export const ASSETFORM_CREATEITEM_LOAD    = 'ASSETFORM_CREATEITEM_LOAD'
export const ASSETFORM_CREATEITEM_SUCCEED = 'ASSETFORM_CREATEITEM_SUCCEED'
export const ASSETFORM_CREATEITEM_FAIL    = 'ASSETFORM_CREATEITEM_FAIL'
export const ASSETFORM_EDITITEM_LOAD      = 'ASSETFORM_EDITITEM_LOAD'
export const ASSETFORM_EDITITEM_SUCCEED   = 'ASSETFORM_EDITITEM_SUCCEED'
export const ASSETFORM_EDITITEM_FAIL      = 'ASSETFORM_EDITITEM_FAIL'
export const ASSETFORM_DELETEITEM_LOAD    = 'ASSETFORM_DELETEITEM_LOAD'
export const ASSETFORM_DELETEITEM_SUCCEED = 'ASSETFORM_DELETEITEM_SUCCEED'
export const ASSETFORM_DELETEITEM_FAIL    = 'ASSETFORM_DELETEITEM_FAIL'