import * as ActionTypes from '../constants/actionTypes'
import api from '../utils/api'
import getFieldFromItem, { fieldLang, getFieldWithLangKey, getNewObjFromDirtyLangObj } from '../utils/getFieldFromItem'

export const keys = []

export const editForm = formFieldsChange => ({
  type: ActionTypes.CV_SELECTED_CASES_CASEFORM_EDITFORM_CHANGE,
  field: formFieldsChange,
})

export const reset = () => ({
  type: ActionTypes.CV_SELECTED_CASES_CASEFORM_ENTER_RESET,
})

export const fetchItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.CV_SELECTED_CASES_CASEFORM_FETCHITEM_LOAD })
  try {
    let item = await api.get(`cv_selected_cases_cases/${params.id}`)
    item = {
      ...item,
      ...getFieldWithLangKey({
        fieldTitle: 'case', objString: item.case, languages: fieldLang,
      }),
    }
    dispatch({
      type: ActionTypes.CV_SELECTED_CASES_CASEFORM_FETCHITEM_SUCCEED,
      item,
    })
    const field = getFieldFromItem(
      item,
      //keys,
    )
    dispatch({
      type: ActionTypes.CV_SELECTED_CASES_CASEFORM_EDITFORM_CHANGE,
      field,
    })
  } catch (error) {
    dispatch({
      type: ActionTypes.CV_SELECTED_CASES_CASEFORM_FETCHITEM_FAIL,
      fetchItemError: error.message,
    })
  }
}

export const createItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.CV_SELECTED_CASES_CASEFORM_CREATEITEM_LOAD })
  try {
    await api.create(`cv_selected_cases_cases`, {
      ...getNewObjFromDirtyLangObj({ fieldTitle: 'case', languages: fieldLang, obj: params }),
    })
    dispatch({ type: ActionTypes.CV_SELECTED_CASES_CASELIST_CHANGETABLE_CHANGE })
    dispatch({ type: ActionTypes.CV_SELECTED_CASES_CASEFORM_CREATEITEM_SUCCEED })
    dispatch({ type: ActionTypes.CV_SELECTED_CASES_CASEFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.CV_SELECTED_CASES_CASEFORM_CREATEITEM_FAIL,
      createItemError: error.message,
    })
  }
}

export const editItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.CV_SELECTED_CASES_CASEFORM_EDITITEM_LOAD })
  try {
    await api.patch(`cv_selected_cases_cases/${params.id}`, {
      ...getNewObjFromDirtyLangObj({ fieldTitle: 'case', languages: fieldLang, obj: params }),
    })
    dispatch({ type: ActionTypes.CV_SELECTED_CASES_CASEFORM_EDITITEM_SUCCEED })
  } catch (error) {
    dispatch({
      type: ActionTypes.CV_SELECTED_CASES_CASEFORM_EDITITEM_FAIL,
      editItemError: error.message,
    })
  }
}

export const deleteItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.CV_SELECTED_CASES_CASEFORM_DELETEITEM_LOAD })
  try {
    await api.delete(`cv_selected_cases_cases/${params.id}`)
    dispatch({ type: ActionTypes.CV_SELECTED_CASES_CASEFORM_DELETEITEM_SUCCEED })
    dispatch({ type: ActionTypes.CV_SELECTED_CASES_CASEFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.CV_SELECTED_CASES_CASEFORM_DELETEITEM_FAIL,
      deleteItemError: error.message,
    })
  }
}
