import moment from 'moment'
import * as ActionTypes from '../constants/actionTypes'
import api from '../utils/api'
import getFieldFromItem, { fieldLang, getFieldWithLangKey, getNewObjFromDirtyLangObj } from '../utils/getFieldFromItem'

export const keys = []

export const editForm = formFieldsChange => ({
  type: ActionTypes.NEWFORM_EDITFORM_CHANGE,
  field: formFieldsChange,
})

export const reset = () => ({
  type: ActionTypes.NEWFORM_ENTER_RESET,
})

export const fetchItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.NEWFORM_FETCHITEM_LOAD })
  try {
    let item = await api.get(`news/${params.id}`)
    item = {
      ...item,
      ...getFieldWithLangKey({
        fieldTitle: 'header', objString: item.header, languages: fieldLang,
      }),
      ...getFieldWithLangKey({
        fieldTitle: 'content', objString: item.content, languages: fieldLang,
      }),
      ...getFieldWithLangKey({
        fieldTitle: 'content_stripped', objString: item.content_stripped, languages: fieldLang,
      }),
      ...getFieldWithLangKey({
        fieldTitle: 'content_stripped_dom', objString: item.content_stripped_dom, languages: fieldLang,
      }),
      ...getFieldWithLangKey({
        fieldTitle: 'content_dom', objString: item.content_dom, languages: fieldLang,
      }),
      date: moment(item.date),
    }
    dispatch({
      type: ActionTypes.NEWFORM_FETCHITEM_SUCCEED,
      item,
    })
    const field = getFieldFromItem(item)
    dispatch({
      type: ActionTypes.NEWFORM_EDITFORM_CHANGE,
      field,
    })
  } catch (error) {
    dispatch({
      type: ActionTypes.NEWFORM_FETCHITEM_FAIL,
      fetchItemError: error.message,
    })
  }
}

export const createItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.NEWFORM_CREATEITEM_LOAD })
  try {
    let obj = {
      ...getNewObjFromDirtyLangObj({ fieldTitle: 'header', languages: fieldLang, obj: params }),
      date: moment(params.date).format('YYYY-MM-DD'),
    }
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'content', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'content_stripped', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'content_dom', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'content_stripped_dom', languages: fieldLang, obj })
    await api.create(`news`, obj)
    dispatch({ type: ActionTypes.NEWLIST_CHANGETABLE_CHANGE })
    dispatch({ type: ActionTypes.NEWFORM_CREATEITEM_SUCCEED })
    dispatch({ type: ActionTypes.NEWFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.NEWFORM_CREATEITEM_FAIL,
      createItemError: error.message,
    })
  }
}

export const editItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.NEWFORM_EDITITEM_LOAD })
  try {
    let obj = {
      ...getNewObjFromDirtyLangObj({ fieldTitle: 'header', languages: fieldLang, obj: params }),
      date: moment(params.date).format('YYYY-MM-DD'),
    }
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'content', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'content_stripped', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'content_dom', languages: fieldLang, obj })
    obj = getNewObjFromDirtyLangObj({ fieldTitle: 'content_stripped_dom', languages: fieldLang, obj })
    await api.patch(`news/${params.id}`, obj)
    dispatch({ type: ActionTypes.NEWFORM_EDITITEM_SUCCEED })
  } catch (error) {
    dispatch({
      type: ActionTypes.NEWFORM_EDITITEM_FAIL,
      editItemError: error.message,
    })
  }
}

export const deleteItem = params => async (dispatch) => {
  dispatch({ type: ActionTypes.NEWFORM_DELETEITEM_LOAD })
  try {
    await api.delete(`news/${params.id}`)
    dispatch({ type: ActionTypes.NEWFORM_DELETEITEM_SUCCEED })
    dispatch({ type: ActionTypes.NEWFORM_ENTER_RESET })
  } catch (error) {
    dispatch({
      type: ActionTypes.NEWFORM_DELETEITEM_FAIL,
      deleteItemError: error.message,
    })
  }
}
